"use strict";

import loadScript from '@elements/load-script';


export function initInScope($scope) {

    loadScript("https://myincert.com/public/api/incertClient.js").done(function () {

        // _config.lang
        let dataLang = $scope.find('#myIncertIframe')[0].getAttribute("data-lang");

        let iframe_src = "https://gutscheine.schladming-dachstein.at/start.php?incertframe=new&sub_shop_id=13&language="+ dataLang + "&ssologin=" + _config.ssologin + "&__drconsent=deny";

        (function(){
            let frameContainerID = 'myIncertIframe',
                landingPageURL = iframe_src
                //trackingCode = 'UA-123456789';
            //incertIframe(frameContainerID, landingPageURL, trackingCode);
            incertIframe(frameContainerID, landingPageURL);
        })();
    });
}
