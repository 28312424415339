"use strict";

export function initInScope ($scope) {
    // custom reset function, due to possibility to reset arrays with values and submit-trigger

    let $btn = $scope.find('.js-ajax-form__custom-reset');
    $btn.on('click', function (evt) {
        let $wrapper = $(this).closest('.js-ajax-form');
        let $form = $wrapper.find('.js-ajax-form__form');
        let $inputs = $form.find(':input');
        evt.preventDefault();

        if ($(evt.target).data('reset-name')) {
            let selector = $(evt.target).data('reset-name')
                .split(',')
                .map(name => `[name="${name.trim()}"]`)
                .join(', ');
            
            $inputs = $inputs.filter(selector);
        }

        if($(evt.target).data('reset-value')) {
            let value = $(evt.target).data('reset-value');
            let selectorValue = `[value="${value}"]`;
            $inputs = $inputs.filter(selectorValue);
        }

        $inputs.filter(':not(:input[type="checkbox"], :input[type="radio"])').val('');
        $inputs.filter(':input[type="radio"], :input[type="checkbox"]').prop('checked', null);
        $inputs.trigger('change');
        
        setTimeout(function() {
            $form.submit();
        }, 200);
    });
}