"use strict";

export function initInScope ($scope) {
    let $characterCounterArea = $scope.find('.js-character-counter');
    let $input = $characterCounterArea.find('.js-character-counter__input');
    let $counter = $characterCounterArea.find('.js-character-counter__counter');
    let $checkIcon = $characterCounterArea.find('.js-character-counter__check');
    let $minLength = $checkIcon.data('minlength');

    $counter.text(0);

    if($input.val() && $input.val().length >= $minLength){
        $counter.text($input.val().length);
        $checkIcon.removeClass("d-none");
    }

    $input.on('keyup', function(){
        $counter.text($input.val().length);
        if($(this).val().length >= $minLength){
            $checkIcon.removeClass("d-none");
        }else{
            $checkIcon.addClass("d-none");
        }
    })
}