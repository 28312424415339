"use strict";
import loadScript from '@elements/load-script';
import {addPolylines, snapToPoints} from "./tourHeightChart";

export function initInScope($scope) {

    loadScript("https://static.panomax.com/front/gallerythumb/2.0/gt.js").done(function () {
        $scope.find('.js-panomax').each(function () {
            let panomaxKey = $(this).attr('data-key');
            let panomaxId = $(this).attr('id');

            if (matchMedia('(min-width: 1921px)')) {
                PmaxGThumb.place({key: panomaxKey, height: "500", id:panomaxId});
            } else {
                PmaxGThumb.place({key: panomaxKey, height: "378", id:panomaxId});
            }
        });
    });
}