"use strict";

import loadScript from '@elements/load-script';


export function initInScope($scope) {

    loadScript("https://myincert.com/public/api/incertClient.js").done(function () {

        // _config.lang
        let dataLang = $scope.find('#myIncertIframe')[0].getAttribute("data-lang");

        let iframe_src = "https://gutscheine.schladming-dachstein.at/start.php?incertframe=new&language="+ dataLang + "&ssologin=" + _config.ssologin + "&__drconsent=deny";

        (function(){
            let frameContainerID = 'myIncertIframe',
                landingPageURL = iframe_src
                //trackingCode = 'UA-123456789';
            //incertIframe(frameContainerID, landingPageURL, trackingCode);
            incertIframe(frameContainerID, landingPageURL);
            let iframe = $('#myIncertIframe_iFrame');


            window.addEventListener('message', function (msg) {
                try{
                    let data = JSON.parse(msg.data);
                    if(data.type === "page-loaded"){
                        /*setTimout function is used because otherwise the scrollTop is triggered before JS of incert and the page would jump down to incert iframe again*/
                        setTimeout(function (){
                            window.scrollTo(0,0);
                        }, 1000);
                    }
                }catch (e){
                    console.log(e);
                }
            });
        })();
    });


}
