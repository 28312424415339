"use strict";

export function initInScope($scope) {
    $scope.find('.js-group-check__change').each(function () {
        let select = $(this);
        select.on({
            focus:function(e){
                select[0].selectedIndex = -1;
            },
            change:function(e){
                const checkboxesCheckByValue = $scope.find('.js-group-check__input[data-group="'+(select.attr('data-group'))+'"][data-check-by-value*="'+(select.val())+'"]');
                const checkboxesShowByValue = $scope.find('.js-group-check__input[data-group="'+(select.attr('data-group'))+'"][data-show-by-value*="'+(select.val())+'"]');
                $scope.find('.js-group-check__input[data-group="'+(select.attr('data-group'))+'"]').prop('checked', false);
                checkboxesCheckByValue.prop('checked', true);
                $scope.find('.js-group-check__input[data-group="'+(select.attr('data-group'))+'"]').closest('.js-group-check__item').addClass('d-none');
                checkboxesShowByValue.closest('.js-group-check__item').removeClass('d-none');
            }
        });
    });
    $scope.find('.js-group-check__button').each(function () {
        let button = $(this);
        button.on({
            click:function(e){
                const checkboxesCheckByValue = $scope.find('.js-group-check__input[data-group="'+(button.attr('data-group'))+'"][data-check-by-value*="'+(button.attr('data-value'))+'"]');
                $scope.find('.js-group-check__input[data-group="'+(button.attr('data-group'))+'"]').prop('checked', false);
                checkboxesCheckByValue.prop('checked', true);
                return false;
            }
        });
    });
}