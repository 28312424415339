"use strict";

import 'slick-carousel';

export function initInScope($scope) {
    $scope.find('.js-opening-slider').each(function () {
        let $slider = $(this);

        let $settings = {
            infinite: false,
            dots: true,
            prevArrow:"<button type='button' class='slick-prev' aria-label='prev'>"+ _config.openingSliderPrev +"</button>",
            nextArrow:"<button type='button' class='slick-next' aria-label='next'>"+ _config.openingSliderNext +"</button>"
        };

        $slider.slick($settings);

        $slider.closest('.collapse').on('shown.bs.collapse', function () {
            $slider.css('visibility','visible');
            $slider.slick('setPosition');
            $slider.slick($settings);
        });

        $slider.closest('.container').find('a[data-toggle="tab"]').on('shown.bs.tab', function () {
            setTimeout(function() {
                $slider.css('visibility','visible');
                $slider.slick('setPosition');
                $slider.slick($settings);
            }, 100);
        });
    });
}
