"use strict";

import loadScript from '@elements/load-script';
import {translate} from '@elements/translations';
import {shuffle} from 'shuffle-seed';

export function init() {
    let seed;

    try {
        seed = sessionStorage.getItem('random-map-seed');
    } catch (e) {}

    if (!seed){
        seed = (Math.random() + 1).toString(36).substring(7);

        try {
            sessionStorage.setItem('random-map-seed', seed);
        } catch (e) {}
    }


    $('head').append('<link rel="stylesheet" type="text/css" href="https://static.maptoolkit.net/api/v9.7.9/mtk.css">');
    let season = _config.season;

    loadScript("https://static.maptoolkit.net/api/v9.7.9/mtk.js").done(function () {
        // helper function to get the correct internationalized text for any object and the current language
        let i18n = (obj, fallback = "", prefix = "", suffix = "") => {
            let str = "";
            try {
                str = obj[MTK.language][0] || fallback
            } catch {
                str = typeof (obj) == "string" ? obj : fallback
            }
            return str ? prefix + str + suffix : str;
        };
        let i18nTwo = (obj, fallback = "", prefix = "", suffix = "") => {
            let str = "";
            try {
                str = obj[MTK.language][1] || fallback
            } catch {
                str = typeof (obj) == "string" ? obj : fallback
            }
            return str ? prefix + str + suffix : str;
        };

        // create a HTML snippet for GeoJSON feature
        let renderFeature = (feature, classList = "") => `
            <div class="map-teaser ${classList}">
                <div class="row no-gutters">
                    <div class="col-md-4">
                        <div class="image embed-responsive embed-responsive-3by2 embed-responsive-cover">
                            <img src="
                                 ${season == "winter" ? feature.properties.thumbnailWinter ? feature.properties.thumbnailWinter : feature.properties.thumbnail : ""}
                                 ${season == "summer" ? feature.properties.thumbnail ? feature.properties.thumbnail : "" : ""}
                                 " class="embed-responsive-item" />
                                 <div class="teaser__badge teaser__badge--small teaser__badge--left teaser__badge--secondary" ${feature.properties.serviceName ? "" : "hidden"}>
                                    <div class="teaser__badge-inner">
                                        <img class="teaser__badge-sommercard" src="/static/build/img/sommercard.png" alt="">
                                        ${i18n(feature.properties.serviceBadgeText)}
                                    </div>
                                </div>
                        </div>
                    </div>
                    <div class="col-md-8">
                        <div class="map-teaser__body text-secondary">
                            <div class="map-teaser__body-top">
                                <span class="teaser__label">${i18n(feature.properties.openings)}</span>
                                <span class="teaser__label teaser__label--secondary">${i18n(feature.properties.customTeaserLabel)}</span>
                                <span class="teaser__label teaser__label--secondary">${i18n(feature.properties.relatedTo)}</span>
                                <span class="teaser__label teaser__label--secondary">${i18nTwo(feature.properties.relatedTo)}</span>
                                <span class="teaser__label teaser__label--secondary">${i18n(feature.properties.relationTo)}</span>
                                
                                <span class="teaser__label teaser__label--tour-${feature.properties.difficulty}" ${feature.properties.difficulty ? "" : "hidden"}>
                                    ${feature.properties.difficulty === 1 ? translate('tour.difficulty.leicht') : ""}
                                    ${feature.properties.difficulty === 2 ? translate('tour.difficulty.mittel') : ""}
                                    ${feature.properties.difficulty === 3 ? translate('tour.difficulty.schwer') : ""}
                                </span>
                                
                                <div>
                                    <div class="teaser-tour__atrribute-item mr-4" ${feature.properties.length ? "" : "hidden"}>
                                        <i class="icon icon-strecke teaser-tour__atrribute-icon"></i>
                                        <span class="teaser-tour__atrribute-text"> ${feature.properties.length} km</span>
                                    </div>
                                    <div class="teaser-tour__atrribute-item" ${feature.properties.duration ? "" : "hidden"}>
                                        <i class="icon icon-uhr teaser-tour__atrribute-icon"></i>
                                        <span class="teaser-tour__atrribute-text"> ${feature.properties.duration} h</span>
                                    </div>
                                </div>
                                
                                <h3 class="h4 map-teaser__hl mb-2 title"><a target="_blank" href="${feature.properties.detaillink[MTK.language]}">${i18n(feature.properties.title, feature.properties.description)}</a></h3>
                                <a target="_blank" href="${feature.properties.detaillink[MTK.language]}" class="map-teaser__detaillink"><i class="icon icon-arrow-right"></i></a>
                                <div class="">
                                    <span>${i18n(feature.properties.categories, feature.properties.categories.de)}</span>
                                </div>
                                <div class="teaser__decorator my-3" hidden></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>`;

        let mapContainersCollection = document.getElementsByClassName('js-toursprung-map--predefined');
        let mapContainers = Array.from(mapContainersCollection);

        mapContainers.map(function (mapContainer) {
            let shuffleOrder = $(mapContainer).hasClass('js-toursprung-map--random')
            let remoteIds = mapContainer.getAttribute('data-item-ids');
            console.log(remoteIds.split(','))
            let mapContainerMap = $(mapContainer).find('.js-toursprung-map__map');
            let mapId = mapContainerMap.attr('id');
            let itemList = $(`[data-id='${mapId}']`);

            /* Initialisierung via url param*/
            if (window.location.hash === '#map-result') {
                if (!MTK.getMap(mapContainerMap))
                    createMap({latitude: 47.4095, longitude: 13.8563, zoom: 10}, remoteIds, mapId, itemList);
            }

            /* Initialisierung initial */
            let initResultTypeMap = _config.initResultTypeMap;
            if (initResultTypeMap) {
                if (!MTK.getMap(mapContainerMap)) {
                    createMap({latitude: 47.4095, longitude: 13.8563, zoom: 10}, remoteIds, mapId, itemList);
                }
            }
            
            // create MTK Map
            function createMap(coords, remoteIds, mapId, itemList) {
                if (remoteIds) {
                    MTK.init({
                        apiKey: _config.toursprungKey,
                        language: _config.lang === 'de' ? 'de' : 'en'
                    }).createMap(
                        mapId,
                        {
                            map: {
                                locationHash: true,
                                location: {
                                    center: [coords.longitude, coords.latitude],
                                    zoom: _config.toursprungZoom || coords.zoom
                                },
                                mapType: "schladming_dachstein-terrain",
                                controls: [
                                    [new mapboxgl.NavigationControl(), "bottom-left"],
                                    /*[new mapboxgl.FullscreenControl(), "bottom-left"],*/
                                ]
                            }

                        },
                        map => {
                            window.map = map;
                            // load sd_pois Features into map
                            let resources = {};
                            window.resources = resources;
                            console.log(window.resources);
                            //let lngLatBounds = new mapboxgl.LngLatBounds(new mapboxgl.LngLat(-90, -90), new mapboxgl.LngLat(90, 90));
                            ["sd_pois", "sd_routes"].forEach(resource => {
                                resources[resource] = new MTK.Resource({
                                    name: resource,
                                    layer: {
                                        type: "symbol",
                                        layout: {
                                            "icon-image": "poi-schladming",
                                            "icon-size": ["interpolate", ["linear"], ["zoom"], 1, 0.3, 13, 0.8, 18, 1],
                                            "icon-ignore-placement": true,
                                            "icon-allow-overlap": true,
                                            "icon-anchor": "bottom",
                                        }
                                    }
                                }).setFilter({
                                    remoteids: remoteIds.split(',')
                                }).on("mouseover", e => {
                                    highlight(e.feature)
                                    // called after the map is dragged and the map-features are loaded for the new boundingbox
                                }).on("idle", () => {
                                    resources[resource].getFeatures((err, features) => {
                                        shuffle(features, seed).forEach(feature => itemList.append($(renderFeature(feature, "listitem"))
                                            .on("click", e => highlightMarker && highlightMarker.fire("click"))
                                            .on("mouseenter", e => highlight(MTK.extend(feature, {properties: feature.vtc_properties})))
                                            .on("mouseleave", e => highlight())
                                        ));
                                    });
                                }).addTo(map);
                            });

                            setTimeout(function () {
                                resources["sd_pois"].getFeatures((err, features) => {
                                    if (features.length > 0) {
                                        let bounds = features.reduce((bounds, feature) => {
                                            return bounds.extend(feature.geometry.coordinates);
                                        }, new mapboxgl.LngLatBounds(features[0].lng, features[0].lat));

                                        map.gl.fitBounds(bounds, {
                                            padding: 50,
                                            duration: 1000
                                        });
                                    }
                                });
                            }, 1500);

                            // empty itemlist on map-drag
                            map.gl.on("moveend", () => itemList.empty());

                            // handle highlight marker
                            let highlightMarker = null, clickMarker = null, clickPolyline = null;
                            let highlight = feature => {
                                if (highlightMarker) highlightMarker.remove();
                                if (feature) highlightMarker = new MTK.Marker()
                                    .setImage({id: "poi-schladming-dunkel", anchor: "bottom"})
                                    .setLabel({
                                        text: i18n(feature.properties.title),
                                        anchor: "left",
                                        offset: [1.5, -1.5]
                                    });
                                highlightMarker.on("mouseout", () => highlightMarker.remove());

                                function highlightedMarker() {
                                    if (clickMarker) clickMarker.remove();
                                    if (clickPolyline) clickPolyline.remove();
                                    clickMarker = highlightMarker;
                                    highlightMarker = null;
                                    resources[feature.properties.resource].fetch(feature, (err, f) => {
                                        if (f.geometry.type.match(/LineString/)) clickPolyline = new MTK.OutlinedPolyline({
                                            color: "#b1c200",
                                            width: 6,
                                            opacity: 1
                                        }).setLngLats(f.geometry.coordinates).addTo(map);
                                        itemList.closest("#sidebar").find('#itemview').show().html(renderFeature(f, "js-go-to-link isCursor"));
                                        itemList.closest("#sidebar").find("#close").show();
                                        itemList.hide();
                                    });
                                }

                                if (matchMedia('(min-width: 768px)').matches) {
                                    highlightMarker.on("click", () => {
                                        highlightedMarker();
                                    }).setLngLat(feature.geometry.coordinates).addTo(map);
                                } else {
                                    highlightedMarker();
                                }

                            };

                            // show the itemlist. this method is called, when a user clicks on the close-button
                            window.showItemList = window.showItemList || ((evt) => {
                                let $element = $(evt.target);
                                let itemList = $element.closest('#sidebar').find('.js-toursprung-map__item-list');

                                itemList.closest("#sidebar").find('#itemview').hide();
                                itemList.closest("#sidebar").find("#close").hide();
                                itemList.show();
                                if (clickMarker) clickMarker.remove();
                                if (clickPolyline) clickPolyline.remove();
                            });


                        }
                    );
                }
            };

            /*Neu Initialisierung der Map nach Filterung*/
            $('.js-ajax-form--toursprung').on('success.ajax-form', (evt) => {
                if (!MTK.getMap(mapId)) {
                    createMap({latitude: 47.4095, longitude: 13.8563, zoom: 10}, remoteIds, mapId, itemList);
                } else {
                    map.gl.remove();
                    delete MTK.maps[mapId];
                    let newRemoteIds = $('#'+mapId).closest('.js-toursprung-map--predefined')[0].getAttribute('data-item-ids');
                    let newItemList = $(`[data-id='${mapId}']`);
                    createMap({latitude: 47.4095, longitude: 13.8563, zoom: 10}, newRemoteIds, mapId, newItemList );
                }
            });

            /* Initialisierung Tab geöffnet */
            $('body').on('shown.bs.tab', '.js-create-predefined-map', function () {
                if (!MTK.getMap(mapId)) {
                    createMap({latitude: 47.4095, longitude: 13.8563, zoom: 10}, remoteIds, mapId, itemList);
                } else {
                    map.gl.remove();
                    delete MTK.maps[mapId];
                    let newRemoteIds = $('#'+mapId).closest('.js-toursprung-map--predefined')[0].getAttribute('data-item-ids');
                    let newItemList = $(`[data-id='${mapId}']`);
                    createMap({latitude: 47.4095, longitude: 13.8563, zoom: 10}, newRemoteIds, mapId, newItemList );
                }
            });

        });
    });
}
