"use strict";

import * as filterStore from "./filter-form/filter-store";

export function initInScope ($scope) {
    $scope.find('.js-additional-filters-toggler').on('change',function (e) {

        let additionalFilter = $(this).find('.js-show-additional-filter:selected').attr('data-additional-filter');

        /*hide all filter areas and remove values*/
        $scope.find('.js-additional-filter').attr('hidden','hidden');
        $('.js-additional-filter input').val('');

        /* show active filter subcategory */
        $scope.find('.js-additional-filter--'+additionalFilter).attr('hidden',false);
    });


    $scope.find('.js-gender-btn').on('click',function (e) {
        let genderValue = $(this).attr('data-value');
        if($(this).hasClass('is-selected')) {
            $('.js-gender-input').val('');
            $('.js-gender-btn').removeClass('is-selected');
        } else {
            $('.js-gender-input').val(genderValue);
            $('.js-gender-btn').removeClass('is-selected');
            $(this).addClass('is-selected');
        }
        $('.js-filter-form').submit();
    });



    let preselectedFilter = $scope.find('.js-show-additional-filter:selected').attr('data-additional-filter');
    /* show active filter subcategory */
    $scope.find('.js-additional-filter--'+preselectedFilter).attr('hidden',false);

}