"use strict";

import {whenValid} from "@elements/parsley-bootstrap-validation";

export function init (){
    let $form = $('.js-loading');
    let $overlay = $(".js-loading__overlay");

    $form.on("submit", function() {
        whenValid($form).then((isValid) => {
            if(isValid) {
                $overlay.attr('hidden', false)
            }else{
                console.error('form has error')
            }
        });
    })
}

