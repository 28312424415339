"use strict";

export function initInScope ($scope) {

    $.getScript("/static/js/libs/summernote-lite.js").done(function() {
        console.log('summernote');
        let $editor = $scope.find('.js-summernote-editor');

        $editor.each(function () {
            $editor.summernote({
                callbacks: {
                    onChange: function(e) {
                        //console.log('onChange');
                        let totalCharacters = $(this).closest(".js-count-characters").find(".note-editable").text().length;
                        //Update value
                        $(this).closest(".js-count-characters").find(".js-count-characters__value").text(totalCharacters);
                        $(this).closest('.js-count-characters').find('.js-count-characters__hidden').val(totalCharacters);
                        $(this).closest('.js-count-characters').find('.js-count-characters__hidden').trigger('change');
                    },
                    onPaste: function (e) {
                        let bufferText = ((e.originalEvent || e).clipboardData || window.clipboardData).getData('Text');
                        e.preventDefault();
                        setTimeout(function(){
                            document.execCommand('insertText', false, bufferText);
                        }, 10);
                    }
                },
                tooltip: false,
                //insertText:'hugo',
                toolbar: $(this).data('toolbar') == 'light' ? [
                    // [groupName, [list of button]]
                    ['font', ['bold', 'italic', 'underline']],
                    ['para', ['ul', 'ol']],
                    ['misc', ['undo', 'redo']],
                    ['font', ['clear']],
                ] : [
                    // [groupName, [list of button]]
                    ['style', ['style']],
                    ['font', ['bold', 'clear']],
                    ['para', ['ul', 'ol']],
                    ['table', ['table']],
                    ['misc', ['undo', 'redo', 'codeview']]
                ],
                styleTags: [
                    'p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'
                ]
            });

            let previewText = $(this).attr('data-preview');

            $(this).summernote('code', previewText);
        });
    });


}