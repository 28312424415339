"use strict";

export function init () {
    console.log('$stickyBottom');

    let $stickyBottom = $('.js-sticky-bottom');
    $(window).on('scroll', () => {
        if (window.pageYOffset < 150) {
            $stickyBottom.removeClass('is-visible');
        } else {
            $stickyBottom.addClass('is-visible');
        }
    });
}