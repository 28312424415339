"use strict";

export function init () {

    function cartItems(){
        let url = _config.cartUrl;
        let $count = $('.js-cart-count');

        $.ajax(url, {
            cache: true,
            dataType : 'json',
            headers: {Ajax: 1, Format: 'json'}
        }).done(function(response) {
            if(response.total){
                if (response.total > 0) {
                    $count.text(response.total);

                    /*show mobile sticky bar*/
                    $('.js-sticky-bottom').addClass('is-visible');
                }
            }
        }).fail(function(e) {
            console.log('fail',e)
        });
    }
    cartItems();


    $('#merchshopCartModal').on('hidden.bs.modal', function () {
        cartItems();
    });
}