"use strict";

export function initInScope ($scope) {
    let lastHeight = 0;
    window.addEventListener('message', function (msg) {
        if (msg.data === "sizing?") {
            parent.postMessage({'size': getHeight()},'*');
        }
    });

    $(window).on('load', function () {
        parent.postMessage({'size': getHeight()},'*');
    });


    function getHeight() {
        return Math.max($('body').height(), $('html').height());
    }

    window.iFrameResizer = {
        targetOrigin: '*'
    };

    $.loadScript("/static/js/libs/iframeResizer.contentWindow.js");
}