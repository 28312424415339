"use strict";

export function initInScope ($scope) {
    if (!sessionStorage.getItem('warning')) {
        $('#warningModal').modal('show');
    }

    $scope.find('.js-close-warning-modal').on('click', function (ev) {
        //$('#warningModal').modal('show');
        sessionStorage.setItem('warning','closed');
    });
}
