"use strict";

export function initInScope ($scope) {
    let $labels = document.querySelectorAll('.js-geolocation-nav__label');

    $labels.forEach(function($label) {
        if ($label.classList.contains('is-active')) {
            $label.classList.remove('is-active');
        }
        let $radio = $label.querySelector('.js-geolocation-nav_radio');
        if($radio.checked) {
            console.log("true");
            $label.classList.add('is-active');
        }
    })
}
