"use strict";

import asyncAppend from "@elements/async-append";
import initModulesInScope from "@elements/init-modules-in-scope";

export function initInScope ($scope) {
    const button = $scope.find('.js-async-tabbing__btn');
    const loader = $('.js-async-tabbing__loading');
    const results = $('.js-async-tabbing__result');
    button.unbind().on({
        click:function(e){
            const currentButton = $(this);
            loader.removeAttr('hidden');
            $.ajax({
                type:'GET',
                url:currentButton.attr('data-url'),
                dataType:'json',
                success:function(data){
                    loader.attr('hidden', 'hidden');
                    button.removeClass('active');
                    currentButton.addClass('active');
                    results.html(data.html);
                    initModulesInScope($scope);
                }
            });

            /*
            asyncAppend({
                target: Array.from(document.querySelectorAll('.js-async-tabbing__result')),
                loading: Array.from(document.querySelectorAll('.js-async-tabbing__loading')),
                notifications: Array.from(document.querySelectorAll('.js-async-tabbing__notifications'))
            }, fetch(_config.watchListUrl.newModal+currentButton.attr('data-filter')).then(response => {
                button.removeClass('active');
                currentButton.addClass('active');
                return response;
            }));
            */
            return false;
        }
    });
}