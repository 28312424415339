"use strict";

export function initInScope ($scope) {
    $scope.find('.js-ajax-form-result-type__link').on('click',function (e) {
        e.preventDefault();
        window.location.hash = this.hash;

        let resultType = $(this).attr('data-result-type');
        let $resultTypeInput = $('.js-ajax-form-result-type__value');

        $resultTypeInput.val(resultType);
    });


    /* show tab */
    if(window.location.hash) {
        $('a[href="' + window.location.hash + '"]').click();
    }
}