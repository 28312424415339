"use strict";

export function initInScope ($scope) {
    //trigger submit on reset for js-ajax-form__reset
    let $baseElement = $scope.find('.js-ajax-form')
    let $resetForm = $baseElement.attr("data-ajax-form-submit-on-reset")

    if($resetForm){
        $baseElement.on("reset.ajax-form", function(){
            let $form = $baseElement.find('.js-ajax-form__form');

            setTimeout(function() {
                $form.submit();
            }, 200);
        })

    }
}