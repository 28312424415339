"use strict";

export function initInScope ($scope) {
    let $lightbox = $scope.find('.js-lightbox');
    let $playbutton = $lightbox.find('.js-play-button');

    $playbutton.on('click', function (evt) {
        let $this = $(this);
        $lightbox.on('onAfterOpen.lg', function (evt) {
            let $asset = $this.closest('.js-lightbox').find('video');
            let $youtube = $this.closest('.js-lightbox').find('iframe');
            if($asset && $asset.length > 0){
                $scope.find('.lg-inner video').attr('autoplay', 'true');
            } else if($youtube && $youtube.length > 0){
                let youtubeSrc = $youtube.attr('src');
                let newSrc = (youtubeSrc.includes('?')) ? (youtubeSrc + '&autoplay=1') : ( youtubeSrc + '?autoplay=1');
                $scope.find('.lg-inner iframe').attr('allow', 'autoplay; encrypted-media').attr('src', newSrc);
            }
        });
    });
}