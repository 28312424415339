"use strict";

export function initInScope () {
    $('.js-scroll-to-detaildates').on('click', function () {
        let collapseItem = $(this).attr('data-target');
        $(collapseItem).collapse('show');

        $('html, body').animate({
            scrollTop: $('#openings').offset().top - 62
        }, 500);
    });
}