import {showNotification} from '@elements/alert-notification';

export function supportsLocalStorage() {
    let supports = false;
    try {
        localStorage.setItem('supportsLocalStorage', 'true');
        supports = !!localStorage.getItem('supportsLocalStorage')
    } catch (e) {}

    return supports;
}

export function throwError(...params) {
    if (process.env.NODE_ENV === 'development') {
        throw new Error(params.map(x => typeof x === 'object' ? JSON.stringify(x): x).join(', '))
    } else {
        console.error(...params);
    }
}

export function getConfig(param) {
    if (typeof param !== 'object'){
        param = {
            name: param,
            optional: false,
            validator: null
        }
    }

    if (!window['_config']) {
        throwError('Global var window._config was not set.');
    }

    let configValue = window['_config'][param.name];
    if (configValue) {
        if (param.validator) {
            if (param.validator(configValue)) {
                return configValue;
            } else {
                throwError(
                    param.validator.message || `Value of config "${param.name}" did not match the validator "${param.validator.name}". Value was` ,
                    configValue
                );
            }
        } else {
            return configValue;
        }
    } else if (!param.optional) {
        throwError(`Missing config "${param.name}". Value of "${param.name}" was not set in _config`, window['_config']);
    }
}

export function requireConfigs (...configs) {
    return configs.map(getConfig);
}

export function isUrl(url) {
    return url && typeof url === 'string' && (url.includes('/') || url.includes('#'));
}


export function getPrefixedDataSet(prefix, $domNode) {
    return Object.entries($domNode.data())
        .filter(([key]) => key.startsWith(prefix))
        .map(([key, value]) => ([lowerCaseFirstLetter(key.replace(prefix, '')), value]))
        .reduce((acc, [key, value]) => ({...acc, [key]: value}), {});
}

export function lowerCaseFirstLetter(string) {
    return string.charAt(0).toLowerCase() + string.slice(1);
}

export function copyToClipboard(element, string, message){
    var inputc = element.appendChild(document.createElement("input"));
    inputc.value = string;
    inputc.focus();
    inputc.select();
    document.execCommand('copy');
    inputc.parentNode.removeChild(inputc);
    showNotification({
        type: 'info',
        content: message,
        closable: true,
        styleModifier: 'alert-notification--image-upload'
    });
    return string;
}