"use strict";

export function initInScope ($scope) {
    $scope.find('.js-show-hidden-input').on('click',function (e) {
        e.preventDefault();
        $(this).closest('.js-hidden-inputs').find('.js-hidden-input-list-item.is-hidden:eq(0)').removeAttr('hidden').removeClass('is-hidden');

        if(!$(this).closest('.js-hidden-inputs').find('.js-hidden-input-list-item.is-hidden').length) {
            $(this).closest('.js-hidden-inputs').find('.js-show-hidden-input').attr('hidden','hidden');
        }
    });


    $scope.find('.js-hidden-input-list-item__remove').on('click',function (e) {
        $(this).closest('.js-hidden-input-list-item').attr('hidden','hidden').addClass('is-hidden');
        $(this).closest('.js-hidden-input-list-item').find('input').val('').removeClass('has-value');
        $(this).closest('.js-hidden-input-list-item').find('select option').prop('selected',false);

        $(this).closest('.js-hidden-input-list-item').find('.form-group').removeClass('has-value');

        $(this).closest('.js-hidden-inputs').find('.js-show-hidden-input').removeAttr('hidden','hidden');
    });

}