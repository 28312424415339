"use strict";

import {onEnterViewPort} from "@elements/viewport-utils";
import {default as initMap, setMarker, showInfoBoxByMarker} from "@elements/leaflet-map";

export function initInScope($scope) {

    let $maps  = $scope.find('.js-leaflet-map');

    if ($maps && $maps.length) {
        console.log('222');

        $('head').append('<link rel="stylesheet" type="text/css" href="https://unpkg.com/leaflet@1.4.0/dist/leaflet.css">')

        $maps.each(function (index, element) {
            let $container = $(element);
            let $mapCanvas = $container.find('.js-leaflet-map__canvas');

            onEnterViewPort($(element), function () {
                import('leaflet').then(function () {
                    const mapLayer = L.tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}', {
                        attribution: '© <a href="https://www.mapbox.com/about/maps/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> <strong><a href="https://www.mapbox.com/map-feedback/" target="_blank">Improve this map</a></strong>',
                        tileSize: 512,
                        maxZoom: 18,
                        zoomOffset: -1,
                        id: 'mapbox/streets-v11',
                        accessToken: _config.mapAccessToken
                    });

                    const poiStyles = {
                        default: {
                            default: L.icon({
                                iconUrl: '/static/build/img/map/marker.svg?v=1',
                                iconSize: [46, 46],
                                iconAnchor: [23, 23],
                                popupAnchor: [0, -30],
                            }),
                            active: L.icon({
                                iconUrl: '/static/build/img/map/marker-active.svg?v=1',
                                iconSize: [46, 46],
                                iconAnchor: [23, 23],
                                popupAnchor: [0, -30],
                            })
                        },
                        special: {
                            default: L.icon({
                                iconUrl: '/static/build/img/map/marker-special.svg?v=1',
                                iconSize: [46, 46],
                                iconAnchor: [23, 23],
                                popupAnchor: [0, -30],
                            }),
                            active: L.icon({
                                iconUrl: '/static/build/img/map/marker-special-active.svg?v=1',
                                iconSize: [46, 46],
                                iconAnchor: [23, 23],
                                popupAnchor: [0, -30],
                            })
                        },
                        current: {
                            default: L.icon({
                                iconUrl: '/static/build/img/map/marker-current.svg?v=1',
                                iconSize: [60, 60],
                                iconAnchor: [30, 30],
                                popupAnchor: [0, -38],
                            }),
                            active: L.icon({
                                iconUrl: '/static/build/img/map/marker-current-active.svg?v=1',
                                iconSize: [60, 60],
                                iconAnchor: [30, 30],
                                popupAnchor: [0, -38],
                            })
                        },
                        poi: {
                            default: L.icon({
                                iconUrl: '/static/build/img/map/poi.svg?v=1',
                                iconSize: [46, 46],
                                iconAnchor: [23, 23],
                                popupAnchor: [0, -30],
                            }),
                            active: L.icon({
                                iconUrl: '/static/build/img/map/poi.svg?v=1',
                                iconSize: [46, 46],
                                iconAnchor: [23, 23],
                                popupAnchor: [0, -30],
                            })
                        }
                    };

                    const infoBoxOptions = {
                        boxStyle: {width: (matchMedia('(max-width: 767px)').matches ? 300: 400), background: "#fff", padding: "10px"},
                        boxClass: "infobox",
                        closeBoxURL: "/static/img/map/close.svg"
                    };

                    let clusteringOptions = {
                        showCoverageOnHover: false,
                        iconCreateFunction: function(cluster) {
                            return L.divIcon({
                                iconUrl: '/static/build/img/map/marker-cluster.svg',
                                iconSize: [46, 46],
                                iconAnchor: [23, 23],
                                className: 'marker-cluster',
                                html: '<img src="/static/build/img/map/marker-cluster.svg"/><span class="marker-cluster__count">' + cluster.getChildCount() + '</span>'
                            });
                        }
                    };

                    let options = $container.data('leaflet-map-options');
                    let poiVarName = $container.data('leaflet-map-pois');
                    let pois = [];

                    console.log(pois);
                    console.log(poiStyles.special);
                    console.log(_leafletMapPois);

                    if (poiVarName) {
                        if (!window['_leafletMapPois']) {
                            console.error('_leafletMapPois is not set');
                        } else if (!window._leafletMapPois[poiVarName]) {
                            console.error(poiVarName + ' is not set in _leafletMapPois', _leafletMapPois);
                        } else {
                            pois = _leafletMapPois[poiVarName];
                        }
                    }

                    let mapObj = initMap({
                        element: $mapCanvas[0],
                        mapOptions: options,
                        mapLayer,
                        poiStyles,
                        infoBoxOptions,
                        clustering: true,
                        clusteringOptions,
                        onActivateMarker,
                        onDeactivateMarker
                    });

                    setMarker(mapObj, pois);
                }).catch(function (a,b,c) {
                    console.error(a,b,c);
                });
            }, {
                offset: window.innerHeight * 1.5
            })
        });
    }
}

function onActivateMarker(marker, mapObj) {
    let detailArea = $('.js-leaflet-map__detail-area');



    if(marker.detailInfoBoxUrl) {
        let infoBoxPromise = showInfoBoxByMarker(
            marker,
            `<div class="my-2">
                <div class="loading-spinner" aria-label="Loading...">
                    <div class="loading-spinner__item loading-spinner__item--1"></div>
                    <div class="loading-spinner__item loading-spinner__item--2"></div>
                    <div class="loading-spinner__item loading-spinner__item--3"></div>
                </div>
            </div>`,
            mapObj
        );

        let contentPromise = $.ajax(marker.detailInfoBoxUrl, {
            cache: true,
            dataType: 'html',
            headers: {Ajax: 1}
        }).catch(function (a, b, c) {
            console.error(a, b, c);
        });

        contentPromise.then(function (infoBoxMarkup) {
            detailArea.html(infoBoxMarkup);
        });

        $.when(infoBoxPromise, contentPromise).then(function (infoBox, [infoBoxContent]) {
            if(infoBox.isOpen()) {
                // open the new info box with the new content only if the old one is still open
                infoBox.setContent(infoBoxContent);
            }
        });
    }
}

function onDeactivateMarker(marker, mapObj) {
    let detailArea = $('.js-leaflet-map__detail-area');
    detailArea.html("");
}