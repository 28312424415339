"use strict";

import {preloadSlide} from "@elements/slider";
import {onEnterViewPort} from "@elements/viewport-utils";

export function initInScope($scope) {

    let $headerSlider = $scope.find('.js-header-slider');

    import('slick-carousel').then(function () {
        $headerSlider.each(function () {
            let $container = $(this);
            let $mainSlider = $container.find('.js-header-slider__main');

            $mainSlider.on('afterChange', function (event, slick, currentSlide) {
                preloadSlide(slick, currentSlide + (matchMedia('(max-width: 767px)').matches ? 2: 1));

                /* set active Nr */
                let currentSlideInt = Math.min(parseInt(currentSlide + 1));
                $mainSlider.closest('.js-header-slider').find('.js-header-slider__current').html(currentSlideInt);

            }).slick({
                dots: true,
                prevArrow:"<button type='button' class='slick-prev slick-arrow--small' aria-label='prev'></button>",
                nextArrow:"<button type='button' class='slick-next slick-arrow--small' aria-label='next'></button>",
                appendArrows: $(this).closest($container).find('.js-header-slider__arrows'),
                infinite: false,
                responsive: [{
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1,
                    }
                }]
            }).each(function () {
                let $slider = $(this);
                onEnterViewPort($slider, function () {
                    preloadSlide(
                        $slider.slick('getSlick'),
                        (matchMedia('(max-width: 767px)').matches ? 2: 1)
                    );
                });
            });
        });
    });
}