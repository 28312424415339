import $ from 'jquery';

// import 'popper.js';
import 'bootstrap/js/dist/alert';
// import 'bootstrap/js/dist/button';
// import 'bootstrap/js/dist/carousel';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/modal';
import 'bootstrap/js/dist/popover';
import 'bootstrap/js/dist/scrollspy';
import 'bootstrap/js/dist/tab';
// import 'bootstrap/js/dist/toast';
import 'bootstrap/js/dist/tooltip';

if (!window.HTMLPictureElement) {
    import('picturefill');
}


let _config = window['_config'] || {};

let app = {
    debug: false,
};
window.app = app || {};
window.app.modules =  app.modules || {};

import initModulesInScope from "@elements/init-modules-in-scope";
app.initModules = function ($scope) {
    console.warn('\'app.initModules\' is going to be deprecated and should not be used anymore. Please import and use \'initModulesInScope\' from \'./scripts/initModulesInScope\' instead.');
    initModulesInScope($scope);
};

$.loadScript = function (url) {
    console.warn('\'$.loadScript\' is going to be deprecated and should not be used anymore. Please use \'import\' for local files or import and use \'loadScript\' for external resources.');

    return import('@elements/load-script').then(function (loadScript) {
        return loadScript.default(url);
    });
};

/**********************************
 *
 *      Project Stuff
 *
 * ********************************/

$('[data-toggle="tooltip"]').tooltip();

$('#searchModal').on('shown.bs.modal', function (e) {
    $('#searchModal input').focus();
});

import * as ajaxInclude from './scripts/ajaxInclude';
app.modules.ajaxInclude = ajaxInclude.initInScope;

import * as goToLink from '@elements/go-to-link';
app.goToLink = goToLink.init;

import * as toc from '@elements/toc';
app.toc = toc.init;
app.modules.toc = toc.initInScope;

import * as tocScroll from './scripts/tocScroll';
app.modules.tocScroll = tocScroll.initInScope;

import * as lazyImg from '@elements/lazy-img';
app.modules.lazyImg = lazyImg.initInScope;

import * as smoothScroll from '@elements/smooth-scroll';
app.modules.smoothScroll = smoothScroll.init({
    scrollOffset: 70
});

import * as childAge from './scripts/childAge';
app.modules.childAge = childAge.initInScope;

import * as shariff from './scripts/shariff';
app.modules.shariff = shariff.initInScope;

import * as collapsingCheckbox from './scripts/collapsingCheckbox';
app.modules.collapsingCheckbox = collapsingCheckbox.initInScope;

import * as watchList from './scripts/watchList';
app.modules.watchList = watchList.initInScope;
app.modules.getWatchListInfo = watchList.getWatchListInfo;

import * as watchlistToggle from './scripts/watchlist-add-toggle';
app.modules. watchlistToggle =  watchlistToggle.initInScope;

import * as cart from './scripts/cart';
app.cart = cart.init;

import * as warningModal from './scripts/warningModal';
app.modules.warningModal = warningModal.initInScope;

import * as bookingRequests from './scripts/bookingRequests';
app.modules.bookingRequests = bookingRequests.initInScope;

import * as masonry from './scripts/masonry';
app.modules.masonry = masonry.initInScope;

import * as navbar from './scripts/navbar';
app.navbar = navbar.init;

import * as rangeSlider from './scripts/rangeSlider';
app.modules.rangeSlider= rangeSlider.initInScope;

import * as iframeSizeBridge from './scripts/iframe-size-bridge';
app.modules.iframeSizeBridge = iframeSizeBridge.initInScope;

import * as imgUpload from './scripts/imgUpload';
app.modules.imgUpload = imgUpload.initInScope;

import * as activeTabViaLink from './scripts/activeTabViaLink';
app.modules.activeTabViaLink = activeTabViaLink.initInScope;

import * as socialWall from './scripts/socialWall';
app.modules.socialWall = socialWall.initInScope;

import * as scrollTop from './scripts/scrollTop';
app.scrollTop = scrollTop.initInScope;

import * as scrollDetailDates from './scripts/scrollDetailDates';
app.modules.scrollDetailDates = scrollDetailDates.initInScope;

import * as stickyBottom from './scripts/stickyBottom';
app.stickyBottom = stickyBottom.init;

import * as starRating from './scripts/starRating';
app.modules.starRating = starRating.initInScope;

import * as incert from './scripts/incert';
app.modules.incert = incert.initInScope;

import * as incertEvent from './scripts/incertEvent';
app.modules.incertEvent = incertEvent.initInScope;

import * as toggleSubcategory from './scripts/toggleSubcategory';
app.modules.toggleSubcategory = toggleSubcategory.initInScope;

import * as mapOverlay from './scripts/mapOverlay';
app.mapOverlay = mapOverlay.init;

import * as animatedCircle from './scripts/animatedCircle';
app.modules.animatedCircle = animatedCircle.initInScope;

import * as personalRecommendationPopup from './scripts/personalRecommendationPopup';
app.modules.personalRecommendationPopup = personalRecommendationPopup.initInScope;

import * as panomax from './scripts/panomax';
app.modules.panomax = panomax.initInScope;

import * as pageNav from './scripts/pageNav';
app.pageNav = pageNav.init;

import * as ajaxFormScrollTop from './scripts/ajax-form-scroll-top';
app.ajaxFormScrollTop = ajaxFormScrollTop.init;

/* todo

import * as scrollWaypoints from './scripts/scrollWaypoints';
app.modules.scrollWaypoints = scrollWaypoints.initInScope;*/

import * as dateInput from '@elements/date-input';
app.modules.dateInput = dateInput.initInScope;

import * as ajaxForm from '@elements/ajax-form';
app.modules.ajaxForm = ajaxForm.createInitInScope({
    addUrlParams: true
});

import * as ajaxFormScroll from './scripts/ajaxFormScroll';
app.modules.ajaxFormScroll = ajaxFormScroll.initInScope;

import * as ajaxFormCustomReset from './scripts/ajaxFormCustomReset';
app.modules.ajaxFormCustomReset = ajaxFormCustomReset.initInScope;

import * as ajaxFormResetOnSubmit from './scripts/ajaxFormResetOnSubmit';
app.modules.ajaxFormResetOnSubmit = ajaxFormResetOnSubmit.initInScope;

import * as ajaxFormResultType from './scripts/ajaxFormResultType';
app.modules.ajaxFormResultType = ajaxFormResultType.initInScope;

import * as ajaxOnLoad from './scripts/ajaxOnLoad';
app.ajaxOnLoad = ajaxOnLoad.initInScope;

import * as toursprung from './scripts/toursprung';
app.toursprung = toursprung.init;

import * as toursprungPredefinedPois from './scripts/toursprungPredefinedPois';
app.toursprungPredefinedPois = toursprungPredefinedPois.init;

import * as tourMap from './scripts/tourMap';
app.modules.tourMap = tourMap.init;

import * as toursprungSinglePoi from './scripts/toursprungSinglePoi';
app.toursprungSinglePoi = toursprungSinglePoi.init;

import * as generalSolutionsMap from './scripts/general-solutions-map';
app.generalSolutionsMap = generalSolutionsMap.init;

import * as reloadForm from "@elements/reload-form";
reloadForm.initInScope($('body'));

import * as imgCopyright from './scripts/imgCopyright';
app.modules.imgCopyright = imgCopyright.initInScope;

//import * as segmentTracking from './scripts/segmentTracking';
//app.modules.segmentTracking = segmentTracking.initInScope;

import * as ratingsSlider from './scripts/ratingsSlider';
app.modules.ratingsSlider = ratingsSlider.initInScope;

import * as imgTeaser from './scripts/imgTeaser';
app.modules.imgTeaser = imgTeaser.initInScope;

import * as modalShow from './scripts/modal-show';
app.modules.modalShow = modalShow.initInScope;

app.modules.iconSlider = slider.createInitInScope('.js-icon-slider', {
    prevArrow: '<button type="button" class="slick-prev slick-arrow--white" aria-label="prev"></button>',
    nextArrow: '<button type="button" class="slick-next slick-arrow--white" aria-label="next"></button>',
    infinite: false,
    slidesToShow: 5,
    slidesToScroll: 5,
    responsive: [
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 1
            }
        },
    ]
});

import * as fileInput from '@elements/file-input';
app.modules.fileInput = fileInput.initInScope;

import * as map from './scripts/map';
app.modules.map = map.initInScope;

/*import * as tourHeightChart from './scripts/tourHeightChart';
app.modules.tourHeightChart = tourHeightChart.init;*/

import * as videoJs from './scripts/videoJs';
app.modules.videoJs = videoJs.initInScope;

import * as videoMute from './scripts/videoMute';
app.videoMute  = videoMute.init;

import * as ddUrlaubszentrumXs from './scripts/ddUrlaubszentrumXs';
app.modules.ddUrlaubszentrumXs  = ddUrlaubszentrumXs.initInScope;

/*import * as outdoorActiveMap from './scripts/outdoorActiveMap';
app.modules.outdoorActiveMap = outdoorActiveMap.initInScope;*/

/*import * as ajaxFormDemiAreaList from '@elements/ajax-form';
app.modules.ajaxFormDemiAreaList = ajaxFormDemiAreaList.createInitInScope({
        addUrlParams: true,
        fetchHeaders: {'Format':'json'}
    },
    {
        base: '.js-ajax-form-demi-area-list',
        result: '.js-ajax-form-demi-area-list__result',
        loading: '.js-ajax-form-demi-area-list__loading',
        notifications: '.js-ajax-form-demi-area-list__notifications',
        errorArea: '.js-ajax-form-demi-area-list__error-area',
        additionalForm: '.js-ajax-form-demi-area-list__additional-form',
        form: '.js-ajax-form-demi-area-list__form',
        link: '.js-ajax-form-demi-area-list__link',
        retry: null
    });*/

import * as additionalMerchCategoryFilters from './scripts/additionalMerchCategoryFilters';
app.modules.additionalMerchCategoryFilters = additionalMerchCategoryFilters.initInScope;

import * as datepicker from '@elements/datepicker';
app.modules.datepicker = datepicker.initInScope;

import * as datepickerPanoramicTour from './scripts/datepickerPanoramicTour';
app.modules.datepickerPanoramicTour = datepickerPanoramicTour.initInScope;

import * as floatingDatepicker from './scripts/floatingDatepicker';
app.modules.floatingDatepicker = floatingDatepicker.initInScope;

import * as datepickerClear from './scripts/datepickerClear';
app.modules.datepickerClear = datepickerClear.initInScope;

import * as boxCheckbox from './scripts/boxCheckbox';
app.modules.boxCheckbox = boxCheckbox.initInScope;

import * as resetProfile from './scripts/resetProfile';
app.modules.resetProfile = resetProfile.initInScope;

import * as hrefSelect from './scripts/hrefSelect';
app.modules.hrefSelect = hrefSelect.initInScope;

import * as addHiddenInputs from './scripts/addHiddenInputs';
app.modules.addHiddenInputs = addHiddenInputs.initInScope;

import * as addAddressCheckbox from './scripts/addAddressCheckbox';
app.modules.addAddressCheckbox = addAddressCheckbox.initInScope;

import * as checkboxTeaser from './scripts/checkboxTeaser';
app.modules.checkboxTeaser = checkboxTeaser.initInScope;

import * as mapListToggle from './scripts/mapListToggle';
app.modules.mapListToggle = mapListToggle.initInScope;

import * as slider from '@elements/slider';
app.modules.slider = slider.initInScope;

app.modules.iconSlider = slider.createInitInScope('.js-icon-slider', {
    prevArrow: '<button type="button" class="slick-prev slick-arrow--white" aria-label="prev"></button>',
    nextArrow: '<button type="button" class="slick-next slick-arrow--white" aria-label="next"></button>',
    infinite: false,
    slidesToShow: 5,
    slidesToScroll: 5,
    responsive: [
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 1
            }
        },
    ]
});

import * as heroSlider from './scripts/heroSlider';
app.modules.heroSlider = heroSlider.initInScope;

import * as openingSlider from './scripts/openingSlider';
app.modules.openingSlider = openingSlider.initInScope;

import * as gamificationSlider from './scripts/gamificationSlider';
app.modules.gamificationSlider = gamificationSlider.initInScope;

import * as feedSelection from './scripts/feedSelection';
app.modules.feedSelection = feedSelection.initInScope;

import * as mobileTable from './scripts/mobileTable';
app.modules.mobileTable = mobileTable.initInScope;

import * as gallerySlider from './scripts/gallerySlider';
app.modules.gallerySlider = gallerySlider.initInScope;

import * as expandable from './scripts/expandable';
app.modules.expandable = expandable.initInScope;

import * as headerSlider from './scripts/headerSlider';
app.modules.headerSlider = headerSlider.initInScope;

import * as infoAlert from './scripts/info-alert';
app.modules.infoAlert = infoAlert.initInScope;

import * as benefitSlider from './scripts/benefit-slider';
app.modules.benefitSlider = benefitSlider.initInScope;

import * as loyaltyTeaserSlider from './scripts/loyalty-teaser-slider';
app.modules.loyaltyTeaserSlider = loyaltyTeaserSlider.initInScope;

/*import * as datepickerRange from './scripts/datepickerRange';*/
import * as datepickerRange from '@elements/datepicker-range';
app.modules.datepickerRange = datepickerRange.initInScope;

import * as lightbox from '@elements/lightbox';
app.modules.lightbox = lightbox.initInScope;

import * as floatingLabel from './libs/floating-label';
app.modules.floatingLabel = floatingLabel.initInScope;

import * as actionChanger from '@elements/action-changer';
app.modules.actionChanger = actionChanger.initInScope;

import * as dropdownKeepOpen from './scripts/dropdownKeepOpen';
app.modules.dropdownKeepOpen = dropdownKeepOpen.initInScope;

import * as toggleAllCheckbox from './scripts/toggleAllCheckbox';
app.modules.toggleAllCheckbox = toggleAllCheckbox.initInScope;

import * as parsley from '@elements/parsley-bootstrap-validation';
app.modules.parsley = parsley.initInScope;

import * as tracking from '@elements/tracking';
let options = {}; // optional options
app.modules.tracking = tracking.initGtmEventTracking(options);

import * as filterFormTracking from './scripts/filterFormTracking';
app.modules.filterFormTracking = filterFormTracking.initInScope;

import * as lightboxVideo from './scripts/lightboxVideo';
app.modules.lightboxVideo = lightboxVideo.initInScope;

import * as ajaxModal from './scripts/ajaxModal';
app.modules.ajaxModal = ajaxModal.initInScope;

import * as summernoteEditor from './scripts/summernote';
app.modules.summernoteEditor = summernoteEditor.initInScope;

import * as geolocationNav from './scripts/geolocation-nav';
app.modules.geolocationNav = geolocationNav.initInScope;

import * as ratingModal from './scripts/ratingModal';
app.modules.ratingModal = ratingModal.initInScope;

import * as galleryAdmin from './scripts/galleryAdmin';
app.modules.galleryAdmin = galleryAdmin.initInScope;

import * as scrollingDatepicker from './scripts/scrollingDatepicker';
app.modules.scrollingDatepicker = scrollingDatepicker.initInScope;

import * as asyncTabbing from './scripts/asyncTabbing';
app.modules.asyncTabbing = asyncTabbing.initInScope;

import * as characterCounter from './scripts/characterCounter';
app.modules.characterCounter = characterCounter.initInScope;

import * as percentageIndicator from './scripts/percentageIndicator';
app.modules.percentageIndicator = percentageIndicator.initInScope;

import * as groupCheck from './scripts/group-check';
app.modules.groupCheck = groupCheck.initInScope;

import * as additionalForm from './scripts/additional-form';
app.modules.additionalForm = additionalForm.initInScope;

import * as loadingOverlay from './scripts/loading-overlay';
app.loadingOverlay = loadingOverlay.init;

import * as trackingCustomerLogin from './scripts/trackingCustomerLogin';
app.modules.trackingCustomerLogin = trackingCustomerLogin.initInScope;

app.filterForm = function () {
    import('./scripts/filter-form/filter-form').then(function (filterForm) {
        app.modules.filterForm = filterForm.initInScope;

        filterForm.init();
        filterForm.initInScope($('body'));
    });

    import('./scripts/filter-form/quick-filter').then(function (quickFilter) {
        app.modules.filterFormquickFilter = quickFilter.initInScope;
        quickFilter.initInScope($('body'));
    });
};

/*import { showCookieBar } from "@elements/cookie-permissions";
showCookieBar();

import * as cookieModalHelpers from './scripts/cookieModalHelpers';
app.modules.cookieModalHelpers = cookieModalHelpers.initInScope;*/

(function( $ ){
    "use strict";

    /* -> _config._preload = Load this functions first */
    if (_config['_preload']) {
        $.each( _config['_preload'], function( _key, _val ){
            if( typeof _val == 'boolean' && typeof window.app[_key] == 'function' ){
                window.app[_key]();
            }
        });
    }

    /* -> _config = Load all others (not _preload and _reload) */
    $.each( _config, function( _key, _val ){
        if( ( typeof _val == 'boolean' && typeof window.app[_key] == 'function' && _key != '_reload' && _key != '_preload' ) ){
            window.app[_key]();
        }
    });

    /* -> _config._reload = Load the ajaxInclued and others after the rest */
    if (_config['_reload']) {
        $.each( _config['_reload'], function( _key, _val ){
            if( ( typeof _val == 'boolean' && typeof window.app[_key] == 'function' ) ){
                window.app[_key]();
            }
        });
    }

    initModulesInScope();
})($);