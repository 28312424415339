import 'bootstrap/js/dist/scrollspy';

export function init() {
    $('body').scrollspy({
        target: '.js-on-page-nav__nav',
        offset: 200
    });

    let $element = $('.js-on-page-nav');
    let offset = $element.attr('data-offset');

    if($('.js-header-left').height() === 0){
        $element.css('left', '0');
    }

    $(window).on('scroll', () => {
        if (window.pageYOffset > offset) {
            $element.addClass('is-visible');
        } else {
            $element.removeClass('is-visible');
        }
    });


    setTimeout(function(){
        /* find links + add to list */
        let $areas = $('.js-page-nav__area');

        if ($areas && $areas.length >= 1) {
            console.log("length: " + $areas.length);
            $('.js-on-page-nav').attr('hidden', null);

            let items = $areas.toArray().map(area => ({
                name: $(area).data('name'),
                id: $(area).attr('id')
            }));

            $('.js-on-page-nav__nav').append(items.map(x =>
                `<li class="nav-item"><a href="#${x.id}" class="nav-link on-page-nav__link js-on-page-nav__link" data-event-tracking-category="Inline Navi" data-event-tracking-action="${x.name}" data-event-tracking-label="`+ _config.currentPage +`">${x.name}</a></li>`
            ).join('')).attr('hidden', null);

            $('.js-on-page-nav__nav').on('click', '.js-on-page-nav__link', function (evt) {
                let $target = $($(this).attr('href'));
                let $collapseParent = $target.closest('.collapse:not(.show)');

                if ($collapseParent && $collapseParent.length) {
                    $collapseParent.addClass('show');
                }

                evt.preventDefault();
                scrollTo(this, $target, 150);
            });
        }

        if(window.location.hash) {
            let target = window.location.hash;
            $('a[href$="'+target+'"]').addClass('active');
        }

    }, 500);


    /* check */
    setTimeout(function(){
        $('body').scrollspy('refresh');
    }, 1500);
}

function scrollTo(el, $target, offset = 0, anchorOffset = 0) {
    let time = $target.offset().top - anchorOffset > 0
        ? ($target.offset().top - anchorOffset)/20
        : (($target.offset().top - anchorOffset)*-1)/20;

    $('html, body').stop().animate({
        scrollTop: $target.offset().top - offset
    }, time, 'swing', function() {

        $target.focus();
        if(el){
            history.replaceState({}, '', el.hash);
        }
        if ($target.is(":focus")) {
            return false;
        } else {
            $target.focus();
        }
    });
}
