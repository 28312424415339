"use strict";

export function initInScope ($scope) {
    let $toc = $scope.find('.js-toc');
    if(window.location.hash) {
        if (window.location.hash.indexOf("toc-entry-target") >= 0){
            let $target = $(window.location.hash);

            let offset = 100;

            if ($toc.data('scroll-offset')) {
                offset = $toc.data('scroll-offset');
            }

            $('html, body').animate({
                scrollTop: $target.offset().top - offset
            }, 2000);

        }
    }
}
