"use strict";

export function initInScope ($scope) {
    let $info = $scope.find('.js-info-alert'),
        $close = $info.find('.js-info-alert__close');

    const COOKIE_NAME = 'infoAlertHidden';
    const COOKIE_DELETE_VALUE = $info.data('expires');

    if (!hasCookie(COOKIE_NAME)) {
        $info.addClass('is-active');
    }

    $close.on('click', function () {
        $info.removeClass('is-active');
        if (!hasCookie(COOKIE_NAME)) {
            document.cookie = COOKIE_NAME + "=true; expires=" + COOKIE_DELETE_VALUE + ";path=/";
        }
    });
}

function hasCookie(cname) {
    return document.cookie.indexOf(cname) >= 0;
}