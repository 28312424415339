export function init() {
    $('.js-ajax-form--scroll-top').on('fetch.ajax-form', function () {
        let $result = $(this).find('.js-ajax-form__result');
        scrollTop($result);
    });
}

export function scrollTop($element) {
    let navOffset = matchMedia('(max-width: 767px)').matches ? 100 : 100;
    let offset = $element[0].getBoundingClientRect().top + window.pageYOffset - navOffset;
    if (window.pageYOffset > offset) {
        try {
            window.scroll({
                top: offset,
                behavior: 'smooth'
            });
        } catch (e) {
            window.scrollTo(0, offset)
        }
    }
}