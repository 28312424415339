"use strict";

import { copyToClipboard } from './utils';
import * as ajaxModal from "./ajaxModal";
import * as ajaxForm from "@elements/ajax-form";

const initAjaxFormInScope =  ajaxForm.createInitInScope({
    addUrlParams: true
});

function getWatchList(callback) {
    var url = _config.watchListUrl.get;
    if(!url) {
        console.warn('_config.watchListUrl.get is not defined');
        return;
    }

    var data;
    $.ajax({
        url: url,
        dataType: 'json',
        success: function (response) {
            data = response;
            callback(data);
            window.app.watchlist = data;
        },
        error: function () {}
    });
}

function setWatchlistCount(count) {
    if(count <= 0){
        $('.js-watchList-count').empty().closest('.js-watchList-link').removeClass('has-items');
    }else {
        $('.js-watchList-count').text(count).closest('.js-watchList-link').addClass('has-items');
    }
}

function watchListAdd(id, type, list) {
    var url = _config.watchListUrl.add;
    if(!url) {
        console.warn('_config.watchListUrl.add is not defined');
        return;
    }

    $.ajax(url, {
        data: 'item=' + id + '&watchlists[]=' + list + '&saveObjectSearch=1',
        dataType: 'json',
        headers: {ajax: true}
    }).done(function (data) {
        if(data.success === true) {
            getWatchList(function(data) {
                setWatchlistCount(data.count)
            });
        }
    }).fail(function(a){
        console.warn(a);
    });
}

function watchListRemove(id, type, list) {
    var url = _config.watchListUrl.add;
    if(!url) {
        console.warn('_config.watchListUrl.add is not defined');
        return;
    }

    $.ajax(url, {
        data: 'item=' + id + '&watchlists[]=' + list + '&saveObjectSearch=1',
        dataType: 'json',
        headers: {ajax: true}
    }).done(function (data) {
        if(data.success === true) {
            getWatchList(function(data) {
                setWatchlistCount(data.count)
            });
        }
    }).fail(function(a){
        console.warn(a);
    });
}

export function initInScope ($scope) {
    console.log("init watchlist called")
    if(!_config.isLoggedIn) {
        $scope.find('.js-watchList-btn').tooltip({ boundary: 'window' });

        /* go to login page, redirect, add item */
        $scope.find('.js-watchList-btn').on('click', function (evt) {

            let name = $(this).data('name');
            let currentPage = ''+ _config.currentPage +'';

            if(window.dataLayer){
                dataLayer.push({
                    'event': 'genericGtmEvent',
                    'genericEventCategory': 'Merkliste - nicht eingeloggt',
                    'genericEventAction': name,
                    'genericEventLabel': currentPage,
                });
            }

            evt.preventDefault();
            evt.stopImmediatePropagation();

            let targetPath = $(this).attr('data-target-path');
            let targetUrl = $(this).attr('href');

            window.location.href = targetUrl+'?target-path='+targetPath;
        });
    } else {
        $('.popover').popover('hide');
        $scope.find('.js-watchList-btn').on({
            'show.bs.popover':function(e){
                $('.popover').popover('hide');
            },
            'shown.bs.popover':function(e){
                initAjaxFormInScope($('.popover'));
                ajaxModal.initInScope($('.popover'));

                $('.js-watchlist-add-toggle').on('click', function (evt) {
                    evt.preventDefault();
                    $('input[name="createNewWatchlist"]').val('1');
                    $('.js-watchlist-add-toggle__input-field').prop('disabled', false);

                    $('.js-watchlist-add-toggle').addClass('d-none');
                    $('.js-watchlist-add-toggle__input').removeClass('d-none');
                })

                $('.bs-popover-hide').on({
                    click:function(){
                        $('.popover').popover('hide');
                    }
                });
            },
            click:function(evt){
                const button = $(this);
                if (typeof button.attr('data-toggle') !== 'undefined' && button.attr('data-toggle') == 'popover'){
                    return false;
                } else {
                    let name = $(this).data('name');
                    let currentPage = ''+ _config.currentPage +'';

                    evt.preventDefault();
                    evt.stopImmediatePropagation();

                    if ($(this).hasClass('is-added')) {
                        $(this).removeClass('is-added');
                        watchListRemove($(this).data('id'), $(this).data('type'), $(this).data('list-id'));
                    } else {
                        $(this).addClass('is-added');
                        watchListAdd($(this).data('id'), $(this).data('type'), $(this).data('list-id'));

                        if(window.dataLayer){
                            dataLayer.push({
                                'event': 'genericGtmEvent',
                                'genericEventCategory': 'Merkliste - eingeloggt',
                                'genericEventAction': name,
                                'genericEventLabel': currentPage,
                            });
                        }

                        $('.js-watchList-count').addClass('has-add-animation');
                        setTimeout(function() {
                            $('.js-watchList-count').removeClass('has-add-animation');
                        }, 1000);
                    }

                    if ($(this).hasClass('js-watchList-btn--remove')) {
                        $(this).closest('.js-watchlist-col').remove();
                    }
                }
            }
        });


        $scope.find('.js-watchList-btn').popover({
            container:'body',
            placement:'bottom',
            content:function(e){
                let listContent = '<form action="'+(window._config.watchListUrl.add)+'" class="js-ajax-form__form">' +
                    '<input type="hidden" name="createNewWatchlist" value="'+(window.app.watchlist.lists ? '0' : '1')+'" />' +
                    '<input type="hidden" name="item" value="'+($(this).data('id'))+'" />' +
                    '<div class="watchlist-popup__list">';
                if (window.app.watchlist.lists){
                    window.app.watchlist.lists.map((item, index) => {
                        listContent += '<div class="custom-checkbox" data-stylelab-tooltip="atoms-checkbox">' +
                                '<label><input class="custom-checkbox__input" type="checkbox" name="watchlists[]" ' +
                                        'value="'+(item.id)+'"'+(item.items.includes($(this).data('id')) ? ' checked' : '')+'>' +
                                            '<span class="custom-checkbox__box icon icon-check"></span><span class="custom-checkbox__text">'+(item.name)+'</span>' +
                                '</label>' +
                                '</div>';
                    });
                }
                listContent += '' +
                    '</div>' +
                    '<div class="mt-3">' +
                    (window.app.watchlist.lists ?
                    '<button type="submit" class="btn btn-secondary btn-light-grey btn-block btn-sm">' +
                        '<span class="icon icon-check watchlist-popup__icon-check ml-3" aria-label="hidden"></span>' +
                        (window._translations.watchlist.saveSelection)+'' +
                    '</button>' : '') +
                    (window.app.watchlist.lists ?
                    '<button type="button" class="btn btn-primary btn-sm btn-block btn-white js-watchlist-add-toggle">' +
                        '<span class="icon icon-plus watchlist-popup__icon-plus ml-3" aria-label="hidden"> </span>' +
                        (window._translations.watchlist.createNewWatchlist) +
                    '</button>' : '') +
                    '<div class="mt-3 watchlist-popup__add js-watchlist-add-toggle__input'+(window.app.watchlist.lists ? ' d-none' : '')+'">' +
                        '<div class="form-group">' +
                            '<label class="form-control-label form-control-label--small" for="watchlist-name">' +
                                (window._translations.watchlist.watchlistName) +
                                '<span aria-label="' + (window._translations.watchlist.requiredField) + '">*</span>' +
                            '</label>' +
                            '<input type="text"'+(window.app.watchlist.lists ? ' disabled' : '')+' class="form-control js-watchlist-add-toggle__input-field" id="name" name="name"' +
                                    'placeholder="' + (window._translations.watchlist.watchlistName) + '"' +
                                    'value="" required="" />' +
                            '<div class="form-errors"></div>' +
                        '</div>' +
                        '<button type="submit" class="btn btn-primary btn-sm btn-block btn-white">' +
                            '<span class="icon icon-plus watchlist-popup__icon-plus ml-3" aria-label="hidden"></span>' +
                            (window._translations.watchlist.createNewWatchlist) +'' +
                        '</button>' +
                        '</div>' +
                    '</div></form>';
                return listContent;
            },
            html:true,
            sanitize:false,
            template:'<div class="watchlist-popup popover" id="watchList-popover" role="tooltip">' +
                '<div class="watchlist-popup__wrapper">' +
                    '<div class="watchlist-popup__title mb-3 popover-header"></div>' +
                        '<div class="js-ajax-form js-ajax-form--add-to-watchlist" data-ajax-form-add-url-params="false">' +
                            '<div class="js-ajax-form__result d-none"></div>' +
                            '<div class="js-ajax-form__loading loading-overlay p-3" hidden>' +
                                '<div class="loading-spinner">' +
                                    '<div class="loading-spinner__item loading-spinner__item--1"></div>' +
                                '<div class="loading-spinner__item loading-spinner__item--2"></div>' +
                            '</div>' +
                        '</div>' +
                        '<div aria-live="polite">' +
                            '<div class="popover-body"></div>' +
                        '</div>' +
                    '</div>' +
                    '<div class="watchlist-popup__close bs-popover-hide">' +
                        '<span class="icon icon-close"></span>' +
                    '</div>' +
                '</div>'
        });
        $('.js-ajax-form--add-to-watchlist').unbind().on('success.ajax-form', (evt) => {
            const itemId = parseInt($(evt.target).find('input[name="item"]').val());
            const jsWatchlistBtn = $('.js-watchList-btn[data-id="'+itemId+'"]');
            let name = jsWatchlistBtn.data('name');
            let currentPage = ''+ _config.currentPage +'';
            getWatchList(function(data){
                setWatchlistCount(data.count)
                const items = Array.isArray(data.items) ? data.items : Object.values(data.items);
                if (!items.includes(itemId)){
                    jsWatchlistBtn.removeClass('is-added');
                } else {
                    jsWatchlistBtn.addClass('is-added');
                    if(window.dataLayer){
                        dataLayer.push({
                            'event': 'genericGtmEvent',
                            'genericEventCategory': 'Merkliste - eingeloggt',
                            'genericEventAction': name,
                            'genericEventLabel': currentPage,
                        });
                    }
                    $('.js-watchList-count').addClass('has-add-animation');
                    setTimeout(function() {
                        $('.js-watchList-count').removeClass('has-add-animation');
                    }, 1000);
                }
                $('.popover').popover('hide');
                if (jsWatchlistBtn.hasClass('js-watchList-btn--remove')) {
                    jsWatchlistBtn.closest('.js-watchlist-col').remove();
                }
            });
        });


    }

    $scope.find('.js-watchList-copy-to-clipboard').unbind().on({
        click:function(){
            copyToClipboard($(this)[0], $(this).attr('href'), window._translations.watchlist.copiedToClipboard);
            return false;
        }
    });

}

export function getWatchListInfo($scope) {
    if(!_config.isLoggedIn) {
        return;
    }

    getWatchList(function(data) {
        var $addButtons = $scope.find('.js-watchList-btn[data-toggle="popover"]');
        if ($addButtons && $addButtons.length) {
            $.each(data.items, function (type, id) {
                $addButtons.filter('[data-id="' + id + '"]').addClass('is-added');
            });
        }
        var $addButtonsList = $scope.find('.js-watchList-btn[data-list-id]');
        if ($addButtonsList && $addButtonsList.length) {
            $addButtonsList.each(function(){
                const addButtonList = $(this);
                if (data.lists && data.lists.filter(item => item.id === parseInt(addButtonList.attr('data-list-id')))[0].items.includes(parseInt(addButtonList.attr('data-id')))){
                    addButtonList.addClass('is-added');
                }
            });
        }
        setWatchlistCount(data.count);
    });
}