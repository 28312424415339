"use strict";

export function init() {

    let $stickyBtn = $('.js-sticky-btn');
    let $closeBtn = $('.js-map-nav__close');
    let $mapNav = $('.js-map-nav');

    let $navItem = $('.js-map-nav-item');
    let $mapItem = $('.js-map-item');
    let $navRegions = $('.js-nav-regions');
    let $svgRegions = $('.js-svg-regions');
    let $valleyIcons = $('.js-valley-icons');

    $stickyBtn.on('click', function (evt) {
        evt.preventDefault();
        $mapNav.toggleClass('is-open');
    });

    $closeBtn.on('click', function (evt) {
        evt.preventDefault();
        $mapNav.removeClass('is-open');
    });

    //hover effects map

    $mapItem.hover(function () {
        /* mouse over */
        ($(this)).addClass('is-hovered');
        let $svgRegion = $(this).data('svg-region');
        /* also hover nav */
        let $navRegion = $navRegions.find('[data-nav-region="' + $svgRegion + '"]');
        $navRegion.addClass('is-hovered');

        /* show valley names */
        let $valleyName = $valleyIcons.find('[class="svg-map__icon-' + $svgRegion + ' inactive"]');
        $valleyName.removeClass('inactive');
        let $target = $valleyName.find('.js-valley-active');
        $target.removeClass('d-none');

    }, function () {
        /* mouse out */
        ($(this)).removeClass('is-hovered');
        let $svgRegion = $(this).data('svg-region');
        let $navRegion = $navRegions.find('[data-nav-region="' + $svgRegion + '"]');
        $navRegion.removeClass('is-hovered');

        /* remove valley names */
        let $valleyName = $valleyIcons.find('[class="svg-map__icon-' + $svgRegion + '"]');
        $valleyName.addClass('inactive');
        let $target = $valleyName.find('.js-valley-active');
        $target.addClass('d-none');
    });

    //hover effects nav

    $navItem.hover(function () {
        /*mouse over*/
        ($(this)).addClass('is-hovered');
        let $navRegion = $(this).data('nav-region');
        let $svgRegion = $svgRegions.find('[data-svg-region="' + $navRegion + '"]');
        $svgRegion.addClass('is-hovered');

        /* show valley names */
        let $valleyName = $valleyIcons.find('[class="svg-map__icon-' + $navRegion + ' inactive"]');
        $valleyName.removeClass('inactive');
        let $target = $valleyName.find('.js-valley-active');
        $target.removeClass('d-none');

    }, function () {
        /*mouse out*/
        ($(this)).removeClass('is-hovered');
        let $navRegion = $(this).data('nav-region');
        let $svgRegion = $svgRegions.find('[data-svg-region="' + $navRegion + '"]');
        $svgRegion.removeClass('is-hovered');

        /* remove valley names */
        let $valleyName = $valleyIcons.find('[class="svg-map__icon-' + $navRegion + '"]');
        $valleyName.addClass('inactive');
        let $target = $valleyName.find('.js-valley-active');
        $target.addClass('d-none');
    });

    // open region link when map is clicked
     $mapItem.click(function () {
         let $svgRegion = $(this).data('svg-region');
         let $navRegionUrl = $navRegions.find('[data-nav-region="' + $svgRegion + '"]').attr('href');

         window.location.href = $navRegionUrl;
     });



}