"use strict";
import {isValid} from "@elements/parsley-bootstrap-validation";
import { getServicePermission  } from "@elements/cookie-permissions";
import loadScript from '@elements/load-script';

export function initInScope ($scope) {
    let $running = 0;
    let $counter = 0;
    let $requestCounterNumber = $scope.find('.js-request-counter-text');
    let $labels = $scope.find('.js-request-label-tags');
    let $requestCounter = $scope.find('.js-request-counter');
    let $requestFormWrapper = $scope.find('.js-request-form-wrapper');
    let $requestFormToggle = $scope.find('.js-request-form--toggle');
    let $requestFormTop = $scope.find('.js-request-scroll-top');
    let $requestForm = $scope.find('.js-request-form');
    let $requestTeaserGrid = $scope.find('.js-request-teasergrid');
    let requestList = [];
    var $submitButton = $requestForm.find('.js-request-form__submit');

    $requestFormToggle.on('click', function (ev) {
        $('body, html').stop().animate({scrollTop:$requestForm.offset().top - 165}, 350);
    });

    $requestFormTop.on('click', function (ev) {
        $('body, html').stop().animate({scrollTop:$requestTeaserGrid.offset().top - 110}, 350);
    });


    loadScript("/static/build/js/libs/jquery.inview.js").then(() => {

        $requestFormWrapper.each(function () {

            $(this).on('inview', function(event, isInView) {

                if (isInView) {
                    $requestFormToggle.removeClass('request-form--toggle-active');
                } else {
                    $requestFormToggle.addClass('request-form--toggle-active');
                }
            });
        });
    }).catch(console.error);

    $scope.find('.js-request-btn').on('click', function (ev) {
        $requestFormWrapper.attr('hidden',false)
        $requestFormToggle.addClass('request-form--toggle-active');

        if($(this).hasClass('is-added')) {
            $counter--;
            $requestCounterNumber.text($counter);
            if($counter === 0) {
                $requestFormWrapper.attr('hidden','hidden')
                $requestFormToggle.removeClass('request-form--toggle-active');
            }
            let $hotelId = $(this).attr('data-hotel-id');
            $('.js-request-form-badge[data-hotel-id="' + $hotelId + '"]').parent().remove();
            $(this).removeClass('is-added');

            for(var i=0; i<requestList.length; i++){
                if(requestList[i].dataId == $hotelId){
                    requestList.splice(i, 1);
                }
            }

        } else {

            $counter++;
            $requestCounterNumber.text($counter);
            if($counter === 0) {
                $requestFormWrapper.attr('hidden','hidden')
                $requestFormToggle.removeClass('request-form--toggle-active');
            }
            let $name = $(this).attr('data-hotel');
            let $hotelId = $(this).attr('data-hotel-id');
            $labels.append('<span class="js-request-form-badge-wrapper"><a class="request-form__badge js-request-form-badge bg-dark text-white px-4 py-2 mt-2 mr-2" data-hotel-id="' + $hotelId + '">' + '<span class="icon icon-close icon-in-text fs0-75 mr-2"></span>' + $name + '</a><input type="hidden" name="accomodation[]" type="text" value="' + $hotelId + '"></span>');
            $(this).addClass('is-added');
            animateCounter();

            let newItem = {
                dataId: $hotelId,
                dataTitle: $name
            };
            requestList.push(newItem);
        }
    });

    $(window).on('scroll', () => {
        if($(window).scrollTop() + $(window).height() >= $(document).height()) {
            $requestFormToggle.attr('hidden','hidden');
        } else {
            $requestFormToggle.attr('hidden',false);
        }
    });

    $("body").on("click", ".js-request-form-badge", function(){
        $counter--;
        $requestCounterNumber.text($counter);
        if($counter === 0) {
            $requestFormWrapper.attr('hidden','hidden')
            $requestFormToggle.removeClass('request-form--toggle-active');
        }
        let $name = $(this).attr('data-hotel');
        let $hotelId = $(this).attr('data-hotel-id');
        let $button = $scope.find('.js-request-btn[data-hotel-id="' + $hotelId + '"]');
        $(this).closest('.js-request-form-badge-wrapper').remove();
        $button.removeClass('is-added');

        for(var i=0; i<requestList.length; i++){
            if(requestList[i].dataId == $hotelId){
                requestList.splice(i, 1);
            }
        }

    });

    function animateCounter(){
        if($running === 0){
            $running = 1;
            $requestCounter.addClass('has-add-animation');
            setTimeout(function() {
                $requestCounter.removeClass('has-add-animation');
                $running = 0;
            }, 1000);
        }
    }

    $requestForm.on('submit', function (e) {
        window.dataLayer = window.dataLayer || [];
        let dateValue = $(this).find('.js-request-form__date').val();
        let dateArrivalYear = dateValue.substring(6,10);
        let dateArrivalMonth;

        if (_config.lang === 'de'){
            dateArrivalMonth = dateValue.substring(3,5);
        }else{
            dateArrivalMonth = dateValue.substring(0,2);
        }

        if (isValid($(this))) {
            for(var i=0;i < requestList.length; i++){
                var title = requestList[i].dataTitle;
                if (getServicePermission('google')) {
                    dataLayer.push({
                        'event': 'gaTriggerEventLP',
                        'gaEventCategory': 'LP-Hotelname-Anfrage',
                        'gaEventAction': title,
                        'gaEventLabel': dateArrivalMonth + ' ' + dateArrivalYear
                    });

                    console.log({
                        'event': 'gaTriggerEventLP',
                        'gaEventCategory': 'LP-Hotelname-Anfrage',
                        'gaEventAction': title,
                        'gaEventLabel': dateArrivalMonth + ' ' + dateArrivalYear
                    });

                    $submitButton.attr('disabled', 'disabled');
                }
            }
        }

    });
}

