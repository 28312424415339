"use strict";
import initModulesInScope from "@elements/init-modules-in-scope";

export function initInScope($scope) {
    $scope.find('.js-feed-selection').each(function () {
        let $selection = $(this);
        let url = $selection.attr('data-feed-url');
        let $teasers = $selection.find('.js-feed-selection__teaser');
        let selectedTeasers = [];
        let $teaserListSubmit = $selection.find('.js-feed-selection__submit');
        let $startSelectionBtn = $selection.find('.js-feed-selection__start-selection');
        let $cancelSelectionBtn = $selection.find('.js-feed-selection__cancel-selection');
        let $selectAllBtn = $selection.find('.js-feed-selection__select-all');
        let $backdrop = $selection.find('.js-feed-selection__backdrop');
        let $banner = $selection.find('.js-feed-selection__banner');
        let $stickyBtn = $selection.find('.js-feed-selection__sticky-btn');



        $cancelSelectionBtn.on('click', function () {
            $startSelectionBtn.removeClass('d-none');
            $teaserListSubmit.addClass('d-none');
            $selectAllBtn.addClass('d-none');
            $backdrop.addClass('d-none');
            $(this).addClass('d-none');
            $banner.addClass('d-none');
            let $selectButtons = $selection.find('.js-feed-selection__btn');
            $selectButtons.each(function () {
                $(this).addClass('d-none');
            });
            $teasers.each(function () {
                if ($(this).hasClass('is-selected')) {
                    $(this).removeClass('is-selected');
                    let $removeText = $(this).find('.js-feed-selection__btn--remove');
                    let $addText = $(this).find('.js-feed-selection__btn--add');
                    $removeText.addClass('d-none');
                    $addText.removeClass('d-none');
                }
            });
            selectedTeasers = [];
        });

        $startSelectionBtn.on('click', function () {
            initModulesInScope($scope);
            let $selectButtons = $selection.find('.js-feed-selection__btn');
            $(this).addClass('d-none');
            $stickyBtn.addClass('profile__sticky-button--selection');
            $cancelSelectionBtn.removeClass('d-none');
            $selectAllBtn.removeClass('d-none');
            $backdrop.removeClass('d-none');
            $banner.removeClass('d-none');
            $selectButtons.each(function () {
                $(this).removeClass('d-none');
            });
            $teasers = $selection.find('.js-feed-selection__teaser');

            $teasers.each(function () {
                let $teaser = $(this);
                let $teaserToggle = $teaser.find('.js-feed-selection__btn');
                let $removeText = $teaser.find('.js-feed-selection__btn--remove');
                let $addText = $teaser.find('.js-feed-selection__btn--add');
                let teaserId = $teaser.attr('data-feed-teaser-id');

                $teaserToggle.on('click', function () {
                    if ($teaserListSubmit.hasClass('d-none')) {
                        $teaserListSubmit.removeClass('d-none');
                    }
                    if ($teaser.hasClass('is-selected')) {
                        $teaser.removeClass('is-selected');
                        selectedTeasers.splice($.inArray(teaserId, selectedTeasers), 1);
                    } else {
                        $teaser.addClass('is-selected');
                        selectedTeasers.push(teaserId);
                    }
                    $removeText.toggleClass('d-none');
                    $addText.toggleClass('d-none');
                });
            });

            $selectAllBtn.on('click', function () {
                $teasers.each(function () {
                    let $teaser = $(this);
                    let $removeText = $teaser.find('.js-feed-selection__btn--remove');
                    let $addText = $teaser.find('.js-feed-selection__btn--add');
                    let teaserId = $teaser.attr('data-feed-teaser-id');
                    if ($teaserListSubmit.hasClass('d-none')) {
                        $teaserListSubmit.removeClass('d-none');
                    }
                    if (!$teaser.hasClass('is-selected')) {
                        $teaser.addClass('is-selected');
                        selectedTeasers.push(teaserId);
                        $removeText.removeClass('d-none');
                        $addText.addClass('d-none');
                    }
                });
            });

        });

        $teaserListSubmit.on('click', function () {
            if(selectedTeasers.length > 0){
                let data = {
                    "ids": selectedTeasers
                };
                let pendingRequest = $.ajax(url, {
                    method: "POST",
                    dataType: 'json',
                    data: data
                }).done(function (response) {
                    window.location.href = response;
                }).always(function () {
                    pendingRequest = null
                });
            }

        });
    });
}

