"use strict";

export function initInScope ($scope) {
    $scope.find('.js-collapsing-checkbox__input').on('change',function (e) {

        let collapsingContainer = $(this).closest('.js-collapsing-checkbox').find('.js-collapsing-checkbox__label').attr('data-target');

        if ($(this).prop('checked')) {

        } else {
            $(collapsingContainer).find('input').prop('checked',false);
        }
    });
}