"use strict";

export function initInScope ($scope) {
    let $link = $scope.find('.js-set-active-tab');

    $link.on('click',function (e) {
        e.preventDefault();

        let $linkHref = $(this).attr('href');
        $('.js-set-active-tablist a.nav-link').removeClass('active');
        $('.tab-pane').removeClass('active show');
        $($linkHref+'-tab').addClass('active');
        $($linkHref).addClass('active show');
    });
}