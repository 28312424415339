"use strict";
import $ from 'jquery'

export function initInScope($scope) {
    $scope.find('.js-scrolling-datepicker').each(function () {
        let $datepicker = $(this);
        let $altField = $datepicker.find('.js-scrolling-datepicker__alt-field');

        $datepicker.on('change', function(){
            let selectedDate = new Date($altField.val());
            let $teasers = $scope.find('.js-scrolling-datepicker-teaser');
            selectedDate = selectedDate.setHours(0,0,0,0);
            let matchingDateExists = false;

            $teasers.each(function (){
                let $teaser = $(this);
                let teaserDate = $teaser.data('scrolling-datepicker-date');
                teaserDate = new Date($teaser.data('scrolling-datepicker-date')).setHours(0,0,0,0);
                if(selectedDate === teaserDate){
                    scrollToTeaser($teaser);
                    matchingDateExists = true;
                    return false;
                }
            });

            if(!matchingDateExists){
                $teasers.each(function (){
                    let $teaser = $(this);
                    let teaserDate = $teaser.data('scrolling-datepicker-date');
                    teaserDate = new Date(teaserDate).setHours(0,0,0,0);

                    if(selectedDate >= teaserDate){
                        scrollToTeaser($teaser);
                        return false;
                    }
                });
            }

            let firstTeaserDate = new Date($teasers.first().data('scrolling-datepicker-date'));
            if (selectedDate > firstTeaserDate){
                scrollToTeaser($teasers.first());
            }
            let lastTeaserDate = new Date($teasers.last().data('scrolling-datepicker-date'));
            if (selectedDate < lastTeaserDate){
                scrollToTeaser($teasers.last());
            }
        });
    });
}

function scrollToTeaser($teaser) {
    $('html, body').stop().animate({
        scrollTop: $teaser.offset().top - 40
    }, 500, 'swing', function() {
        $teaser.focus();
        if ($teaser.is(":focus")) {
            return false;
        } else {
            $teaser.attr('tabindex','-1');
            $teaser.focus();
        }
    });
}