"use strict";

export function initInScope ($scope) {
    const button = $scope.find('.js-ajax-modal__btn');
    button.unbind().on({
        click:function(e){
            const currentButton = $(this);
            currentButton.attr('disabled', 'disabled');
            var url = currentButton.attr('data-src');
            $.ajax({
                type:'GET',
                url:url,
                data:typeof currentButton.attr('data-data') !== 'undefined' ? currentButton.attr('data-data') : null,
                dataType:'json',
                success:function(data){
                    currentButton.removeAttr('disabled');
                    const modal = $(data.html);
                    $('body').append(modal);
                    modal.modal('show');
                    modal.on({
                        'shown.bs.modal':function(){
                            app.modules['ajaxForm'](modal);
                        },
                        'hidden.bs.modal':function(){
                            modal.remove();
                        }
                    });
                }
            });
        }
    });
}