"use strict";

export function initInScope ($scope) {
    let $address = $scope.find('.js-address-container');
    $scope.find('.js-add-address-checkbox input').on('change',function (e) {
        e.preventDefault();
        if($(this).prop('checked')) {
            $address.attr('hidden',false);
        } else {
            $address.attr('hidden','hidden');
        }
    });
}