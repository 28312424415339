"use strict";

export function init () {

    let $navbar = $('.js-navbar'),
        $header = $('header'),
        $stickyBtn = $('.js-sticky-btn'),
        $navbarHeader = $('.js-navbar__header'),
        $navbarToggle = $('.js-navbar__toggle'),
        $subnavToggle = $('.js-toggle-subnav'),
        $subSubnav1Toggle = $('.js-toggle-sub-subnav-1'),
        $subSubnav2Toggle = $('.js-toggle-sub-subnav-2'),
        $subSubnav3Toggle = $('.js-toggle-sub-subnav-3'),
        $navBack = $('.js-toggle-nav-back'),
        $navBackFirstLevel = $('.js-toggle-nav-back--to-first-level');


    $navbarToggle.on('click', function (evt) {
        evt.preventDefault();

        $navbarToggle.toggleClass('active');
        $navbar.toggleClass('is-open');
        $header.toggleClass('nav-is-open');
        $navbarHeader.toggleClass('navbar-open');
        //$('body').toggleClass('ov-hidden');
        if($('.js-has-children--main').hasClass('active')) {
            //console.log('is active');
        } else {
            $stickyBtn.toggleClass('is-visible');
        }
    });

    $subnavToggle.on('click', function (evt) {
        evt.preventDefault();
        if($(this).closest('li.js-has-children--main').hasClass('active')) {
            $(this).closest('li.js-has-children--main').removeClass('active');
        } else {
            $('.js-has-children--main').removeClass('active');
            $(this).closest('li.js-has-children--main').addClass('active');
        }
        $stickyBtn.removeClass('is-visible');
    });

    $subSubnav1Toggle.on('click', function (evt) {
        evt.preventDefault();
        if($(this).closest('li.js-has-children--sub-1').hasClass('active')) {
            $(this).closest('li.js-has-children--sub-1').removeClass('active');
        } else {
            $('.js-has-children--sub-1').removeClass('active');
            $(this).closest('li.js-has-children--sub-1').addClass('active');
        }
        $stickyBtn.removeClass('is-visible');
    });

    $subSubnav2Toggle.on('click', function (evt) {
        evt.preventDefault();
        if($(this).closest('li.js-has-children--sub-2').hasClass('active')) {
            $(this).closest('li.js-has-children--sub-2').removeClass('active');
        } else {
            $('.js-has-children--sub-2').removeClass('active');
            $(this).closest('li.js-has-children--sub-2').addClass('active');
        }
        $stickyBtn.removeClass('is-visible');
    });

    $subSubnav3Toggle.on('click', function (evt) {
        evt.preventDefault();
        if($(this).closest('li.js-has-children--sub-3').hasClass('active')) {
            $(this).closest('li.js-has-children--sub-3').removeClass('active');
        } else {
            $('.js-has-children--sub-3').removeClass('active');
            $(this).closest('li.js-has-children--sub-3').addClass('active');
        }
        $stickyBtn.removeClass('is-visible');
    });

    $navBack.on('click', function (evt) {
        evt.preventDefault();
        $(this).closest('.js-has-children').removeClass('active');
    });

    $navBackFirstLevel.on('click', function (evt) {
        $stickyBtn.addClass('is-visible');
    });


    if (matchMedia('(min-width: 768px)').matches) {
        $(window).on('scroll', () => {
            if (window.pageYOffset > 250) {
                $('.js-scroll-brand').addClass('hide-brand');
                $('.js-sticky-cta').addClass('show-cta');
            } else {
                $('.js-scroll-brand').removeClass('hide-brand');
                $('.js-sticky-cta').removeClass('show-cta');
            }
        });
    }

    /*if (matchMedia('(min-width: 768px)').matches) {
        $(document).click(function(event) {
            if($navbarHeader.hasClass('navbar-open')) {
                if (!$(event.target).closest(".navbar-left, .js-navbar__toggle").length) {
                    $navbarToggle.removeClass('active');
                    $navbar.removeClass('is-open');
                    $navbarHeader.removeClass('navbar-open');
                    //$('body').removeClass('ov-hidden');
                }
            }
        });
    }*/

};