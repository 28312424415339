"use strict";

export function initInScope ($scope) {
    $scope.find('.js-toogle-all-checkbox__input-all').on('change',function (e) {

        if ($(this).prop('checked')) {
            $(this).closest('.js-toogle-all-checkbox').find('.js-toogle-all-checkbox__input').prop('checked','checked');
        } else {
            $(this).closest('.js-toogle-all-checkbox').find('.js-toogle-all-checkbox__input').prop('checked',false);
        }
    });


    $scope.find('.js-toogle-all-checkbox__input').on('change',function (e) {
        $(this).closest('.js-toogle-all-checkbox').find('.js-toogle-all-checkbox__input-all').prop('checked',false);
    });
}