"use strict";

export function initInScope () {
    if (window.innerHeight * 2 > document.body.scrollHeight) {
        /*show button only on long pages*/
        return;
    }
    let isHidden = true;
    let $scrollTopBtn = $('.js-scroll-top-btn').on('click', function (evt) {
        evt.preventDefault();
        $('body, html').stop().animate({
            scrollTop: 0
        }, 300 , 'swing');
    });
    $(window).on('scroll', () => {
        if (window.pageYOffset < 750) {
            if (!isHidden) {
                $scrollTopBtn.attr('hidden','hidden');
                isHidden = true;
            }
        } else {
            if (isHidden) {
                $scrollTopBtn.removeAttr('hidden');
                isHidden = false;
            }
        }
        if($(window).scrollTop() + $(window).height() >= $(document).height()) {
            $scrollTopBtn.attr('hidden','hidden');
            isHidden = true;
        }
    });
}