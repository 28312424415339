"use strict";

export function initInScope($scope) {

    $scope.find('.js-expandable').each(function () {
        let expandableElement = $(this);
        let expandableContent = expandableElement.find('.js-expandable__content');
        let expandableHeight = expandableElement.data('expandable-height') || 350;

        //console.log('expandableHeight ', expandableHeight);

        if (expandableContent.height() > expandableHeight) {
            expandableElement.addClass('is-expandable');

            expandableElement.find('.js-expandable__more').on('click', function (evt) {
                evt.preventDefault();
                expandableElement.toggleClass('is-collapsed');
            }).on('click', function (evt) {
                evt.preventDefault();
            });
        } else {
            expandableElement.addClass('is-not-expandable');
        }
    });
}