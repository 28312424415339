"use strict";

import loadScript from '@elements/load-script';
export function init() {

    $('head').append('<link rel="stylesheet" type="text/css" href="https://static.maptoolkit.net/api/v9.7.9/mtk.css">');
    $('head').append('<link rel="stylesheet" type="text/css" href="https://static.maptoolkit.net/api/v9.7.9/elevationprofile.css">');

    loadScript("https://static.maptoolkit.net/api/v9.7.9/mtk.js").done(function () {
        loadScript("https://static.maptoolkit.net/api/v9.7.9/elevationprofile.js").done(function () {

            let tour = $('.js-toursprung-map--tour').attr('data-tour');
            let profileData = $('.js-toursprung-map--tour').attr('data-profile');

            let polyline = new MTK.OutlinedPolyline({ color: "#b1c200", width: 6, opacity: 1 });
            polyline.loadKML(tour, () => {
                MTK.init({
                    apiKey: _config.toursprungKey,
                    language: _config.lang
                }).createMap("map", {
                    map: {
                        locationHash: false,
                        location: { bounds: polyline.getBounds(), fitBoundsOptions: { padding: 100 } },
                        mapType: "schladming_dachstein-terrain",
                        controls: [
                            [new mapboxgl.NavigationControl(), "bottom-left"],
                            /*[new mapboxgl.FullscreenControl(), "bottom-left"],*/
                        ]
                    }
                }, map => {
                    polyline.addTo(map);
                    new MTK.ElevationProfile({ highlightColor: "#fff" }).setProfile(profileData).addTo(map);
                    new MTK.Marker().setImage({ id: "poi-schladming", anchor: "bottom"}).setLngLat(polyline.lngLats[0]).addTo(map);
                    // elevation.css overwrites
                    $(document.head).append($("<style>").html(`
                        .mtk-elevation-profile.mtk-elevation-control .mtk-elevation-background { fill: #434f29; opacity: 0.7 }
                        .mtk-elevation-profile.mtk-elevation-control .mtk-elevation-curve-fill { opacity: 0.6 !important }
                        .mtk-elevation-profile.mtk-elevation-control .mtk-elevation-section { fill: rgba(177, 194, 0, 0.4) }
                        .mtk-elevation-profile.mtk-elevation-control .mtk-elevation-curve-bar { stroke: #b1c200 }
                    `));
                });
            });
        });
    });
}
