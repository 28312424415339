import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import {getConfigValue} from '@elements/config-utils';
import {translate} from '@elements/translations';
import {showNotification} from '@elements/alert-notification';
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import arrayMove from './array-move';

let id = 0;

class Gallery extends Component {
    constructor(props) {
        super();
        this.state.items = props.initialItems || [];
        this.state.isRating = props.isRating || false;
        this.state.selections = [];
    }

    deleteItem(id) {
        this.setState({
            items: this.state.items.filter(item => item.id !== id)
        });
    }

    onInputChange(evt) {
        let isValidFile = true;

        let files = Array.from(evt.target.files);

        files.map(function(inputItem){
            if (!inputItem.type.match('image.*')) {
                isValidFile = false;
            }
        });

        if(isValidFile){
            this.setState({
                selections: this.state.selections.concat([evt.target.files]),
                items: this.state.items.concat(
                    Array.from(evt.target.files).map(file => ({
                            id: 'dynamic-id-' + id++,
                            src: URL.createObjectURL(file),
                            inputs: {}
                        })
                    )
                )
            });
            showNotification({
                type: 'light',
                title: 'Erfolgreich hinzugefügt',
                content: '<div class="" hidden></div>',
                closable: true,
                styleModifier: 'alert-notification--image-upload',
                icon: ''
            });
        }
        else {
            showNotification({
                type: 'light',
                title: translate('Ungültiges Format'),
                content: '<div class="" hidden></div>',
                closable: true,
                styleModifier: 'alert-notification--image-upload',
                icon: ''
            });
        }

    }

    onSortEnd({oldIndex, newIndex}) {
        this.setState(({items}) => ({
            items: arrayMove(items, oldIndex, newIndex),
        }));
    }

    render(props, state) {
        // we have to keep all previous selections due to security restrictions.
        // It is not possible to programmatically set the value for file inputs.
        // An empty element is appended at the end to show an empty file input.
        let inputSelections = this.state.selections.concat([[]]);

        return (
            <div>
                {inputSelections.map((_, index) => (
                    <div className="custom-file-upload mt-2" hidden={index !== inputSelections.length - 1}
                         key={index}>
                        <div className="custom-file-upload__inner">
                            <div className="custom-file-upload__body text-center">
                                <div>
                                    <span className="my-2 custom-file-upload__desc d-none d-md-block">{translate('Foto hier rein ziehen oder')}</span>
                                    <span className="my-2 custom-file-upload__desc d-block d-md-none">{translate('Foto hier hochladen')}</span>
                                </div>
                                <button type="button" className="btn btn-default my-2">
                                    {translate('Datei auswählen')}
                                </button>
                            </div>
                            <input onChange={(evt) => this.onInputChange(evt)}
                                   multiple="true"
                                   name="gallery-file-upload[]"
                                   type="file"
                                   accept="image/*"
                            />
                        </div>
                    </div>
                ))}

                <GalleryList
                    pressDelay={(matchMedia('(max-width: 767px)').matches ? 5000 : 250)}
                    onSortEnd={(...params) => this.onSortEnd(...params)}
                    items={this.state.items}
                    isRating={this.state.isRating}
                    onDeleteItem={(id) => this.deleteItem(id)}
                />
            </div>
        );
    }
}

const GalleryList = SortableContainer(({items, onDeleteItem, onSortEnd, isRating}) => {
    if(isRating){
        return (
            <div className="gallery-admin-img-container">
                {items.map((item, index) => (
                    <GalleryRatingItem
                        key={`item-${item.id}`}
                        index={index}{...item}
                        onDelete={() => onDeleteItem(item.id)}
                        onMoveUpClick={() => onSortEnd({oldIndex: index, newIndex: index - 1})}
                        onMoveDownClick={() => onSortEnd({oldIndex: index, newIndex: index + 1})}
                        showMoveUpButton={index !== 0}
                        showMoveDownButton={index !== items.length - 1}
                    />
                ))}
            </div>
        );
    }
    else {
        return (
            <div className="gallery-admin-img-container">
                {items.map((item, index) => (
                    <GalleryItem
                        key={`item-${item.id}`}
                        index={index}{...item}
                        onDelete={() => onDeleteItem(item.id)}
                        onMoveUpClick={() => onSortEnd({oldIndex: index, newIndex: index - 1})}
                        onMoveDownClick={() => onSortEnd({oldIndex: index, newIndex: index + 1})}
                        showMoveUpButton={index !== 0}
                        showMoveDownButton={index !== items.length - 1}
                    />
                ))}
            </div>
        );
    }

});

const GalleryItem = SortableElement((props) => (
    <div className="gallery-admin-row">
        <button className="gallery-admin-position gallery-admin-position--top" disabled={!props.showMoveUpButton} type="button" onClick={props.onMoveUpClick}><i className="icon icon-arrow-right icon-rotate-270"></i></button>
        <button className="gallery-admin-position gallery-admin-position--bottom" disabled={!props.showMoveDownButton} type="button" onClick={props.onMoveDownClick}><i className="icon icon-arrow-right icon-rotate-90"></i></button>
        <div className="row row--gutter-width-10">
            <div className="col-lg-4">
                <img className="img-fluid" src={props.src} alt=""/>
            </div>
            <div className="col-lg-8 pl-md-4">
                <div className="row row--gutter-width-20">
                    <div className="col-md-12 mb-3">
                        <FormGroup
                            name={'copyright[' + props.id + ']'}
                            defaultValue={props.inputs['copyright']}
                            required={true}>
                            {translate('gallery-upload.copyright')} *
                        </FormGroup>
                    </div>
                    <div className="col-md-6 mb-3">
                        <FormGroup
                            name={'title[de][' + props.id + ']'}
                            defaultValue={props.inputs['title[de]']}
                            required={true}>
                            {translate('gallery-upload.title.german')} *
                        </FormGroup>
                    </div>
                    <div className="col-md-6 mb-3">
                        <FormGroup
                            name={'title[en][' + props.id + ']'}
                            defaultValue={props.inputs['title[en]']}
                            required={false}>
                            {translate('gallery-upload.title.english')}
                        </FormGroup>
                    </div>
                    <div className="col-12">
                        <Checkbox
                            defaultValue={props.inputs['authorization']}
                            name={'authorization[' + props.id + ']'}
                            required={true}>
                            {translate('gallery-upload.authorization')} *
                        </Checkbox>
                    </div>
                    <div className="col-12">
                        <Checkbox
                            defaultValue={props.inputs['licence']}
                            name={'licence[' + props.id + ']'}>
                            {translate('gallery-upload.licence')}
                        </Checkbox>
                    </div>

                    <div className="col-md-12 mb-3 align-self-end mb-3 text-right">
                        <button className="btn btn-eem" type="button" onClick={props.onDelete}>
                            <i className="icon icon-delete"/> {translate('entfernen')}
                        </button>
                    </div>
                </div>

                <input type="hidden" name={'index[' + props.id + ']'} value={props.index}/>
                <input type="hidden" name={'src[' + props.id + ']'} value={props.src}/>
            </div>
        </div>
    </div>
));

const GalleryRatingItem = SortableElement((props) => (
    <div className="gallery-admin-row">
        <button className="gallery-admin-position gallery-admin-position--top" disabled={!props.showMoveUpButton} type="button" onClick={props.onMoveUpClick}><i className="icon icon-arrow-right icon-rotate-270"></i></button>
        <button className="gallery-admin-position gallery-admin-position--bottom" disabled={!props.showMoveDownButton} type="button" onClick={props.onMoveDownClick}><i className="icon icon-arrow-right icon-rotate-90"></i></button>
        <div className="row row--gutter-width-10">
            <div className="col-lg-8">
                <div className="embed-responsive embed-responsive-3by2 embed-responsive-cover">
                    <img className="embed-responsive-item" src={props.src} alt=""/>
                </div>
            </div>
            <div className="col-lg-4">
                <div className="mb-3 align-self-end mb-3 text-right">
                    <button className="btn btn-eem" type="button" onClick={props.onDelete}>
                        <i className="icon icon-delete"/> {translate('entfernen')}
                    </button>
                </div>
                <input type="hidden" name={'index[' + props.id + ']'} value={props.index}/>
                <input type="hidden" name={'src[' + props.id + ']'} value={props.src}/>
            </div>
        </div>
    </div>
));

function FormGroup(props) {
    return (
        <div className="form-group form-group--eem">
            <label htmlFor="name" className="form-control-label form-control-label--eem">
                {props.children}
            </label>
            <input id="name"
                   type="text"
                   className="form-control form-control--eem"
                   name={props.name}
                   defaultValue={props.defaultValue}
                   required={props.required}/>
            <div className="form-errors"/>
        </div>
    );
}

function Checkbox(props) {
    return (
        <div className="form-group form-group--eem">
            <div className="custom-checkbox">
                <label>
                    <input
                        className="custom-checkbox__input"
                           type="checkbox"
                           name={props.name}
                            checked={props.defaultValue}
                           required={props.required} />
                        <span className="custom-checkbox__box icon icon-check"></span>
                        <span className="custom-checkbox__text">
                            {props.children}
                        </span>
                </label>
                <div className="form-errors form-errors--eem-checkbox"/>

            </div>
        </div>
    );
}

export function initInScope($scope) {

    let initialStateByGalleryId = getConfigValue('galleryAdminInitialState');
    $scope.find('.js-gallery-admin').each(function () {
        ReactDOM.render(<Gallery initialItems={initialStateByGalleryId[$(this).data('gallery-admin-id')]} isRating={false}/>, this);
    });

    $scope.find('.js-gallery-admin-rating').each(function () {
        let galleryImages = $(this).data('gallery-images');
        ReactDOM.render(<Gallery initialItems={galleryImages} isRating={true}/>, this);
    });
}