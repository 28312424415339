"use strict";

export function initInScope ($scope) {
    $scope.find('.js-childCountSelect').on('keyup input change', function () {

        let val = $(this).val();
        let childAgeInputs = $(this).closest('form').find('.js-childAgeInputs .js-child-input');
        let childAgeInputCol = $(this).closest('form').find('.js-childAgeInputs .js-child-input-col');

        childAgeInputs.attr('hidden',true).attr( 'required', null );
        childAgeInputCol.attr('hidden',true);

        if( val == 0 ) {
            $( childAgeInputs).closest( '.js-childAgeContainer').attr( 'hidden',true );
        } else {
            $( childAgeInputs).closest( '.js-childAgeContainer').removeAttr( 'hidden' );
        }

        for (let i=0; i < childAgeInputs.length && i < val; i++) {
            childAgeInputs.eq(i).removeAttr('hidden').attr( 'required', 'required' );
            childAgeInputCol.eq(i).removeAttr('hidden');
        }

        let form = $(this).closest('.js-parsley');
        if (form && form.length) {
            form.parsley().destroy();
            app.modules.parsley(form.parent());
        }
    });
}