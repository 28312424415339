"use strict";

export function initInScope ($scope) {
    $scope.find('.js-box-checkbox__input').each(function(){

        let $thisCheckbox = $(this),
            $checkboxImage = $thisCheckbox.closest('.js-box-checkbox').find('.js-box-checkbox__image');


        if($checkboxImage.length){
            setCheckboxImage($thisCheckbox);

            $thisCheckbox.on('change', function (e) {
                setCheckboxImage($thisCheckbox);
            });
        }else{
            setCheckboxState($thisCheckbox);

            $thisCheckbox.on('change', function (e) {
                setCheckboxState($thisCheckbox);
            });
        }

    });
}

export function setCheckboxImage($thisCheckbox) {

    let $checkboxParent = $thisCheckbox.closest('.js-box-checkbox'),
        $checkboxImage = $checkboxParent.find('.js-box-checkbox__image'),
        $checkboxImageName = $checkboxImage.data('image');

    if($thisCheckbox.is(':checked')){
        $checkboxParent.addClass('is-checked');

    }else{
        $checkboxParent.removeClass('is-checked');
    }
}

export function setCheckboxState($thisCheckbox) {

    let $checkboxParent = $thisCheckbox.closest('.js-box-checkbox');

    if($thisCheckbox.is(':checked')){
        $checkboxParent.addClass('is-checked');
    }else{
        $checkboxParent.removeClass('is-checked');
    }
}