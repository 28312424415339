"use strict";

export function initInScope($scope) {

    $scope.find('.wysiwyg table').each(function () {
        $(this).removeAttr('cellspacing').removeAttr('cellpadding').removeAttr('border').removeAttr('width');

        if(matchMedia('(max-width: 767px)').matches) {
            if(!_config.admin) {
                /* if in collapse */
                $('.collapse').on('shown.bs.collapse', function () {
                    if($(this).find('table-responsive').width() <= $(this).find('table').width()) {
                        $("<div class='xs-table-scroller text-right'><span class='icon icon-arrow-tablescroll'></span></div>").insertBefore($(this).find('table').closest('.table-responsive'));
                    }
                });

                /* default table in wysiwyg */
                if($(this).closest('.table-responsive').width() <= $(this).width()) {
                    $("<div class='xs-table-scroller text-right'><span class='icon icon-arrow-tablescroll'></span></div>").insertBefore($(this).closest('.table-responsive'));
                }

            }
        }

    });
}
