"use strict";

export function initInScope ($scope) {
    let container = $scope.find('.js-recommendation-popup');
    let close = $scope.find('.js-recommendation-popup__close');
    let timeFrom = container.attr('data-time-from');
    let timeTo = container.attr('data-time-to');
    let snippetId = container.attr('data-id');
    let currentDate = Math.round((new Date()).getTime());
    let dismissedUntil = (container.attr('data-dismissed-days') * 86400000) + currentDate;

    //check if popup should be shown currently
    let isDateTime = (currentDate > timeFrom && currentDate < timeTo);
    //check if popup has been dismissed recently
    let isDismissed = checkPopupDismissed(container, snippetId, currentDate);

    if(isDateTime && !isDismissed){
        //show popup
        container.removeAttr('hidden');
    }

    close.on('click', function (ev) {
        //set dismissed-until
        localStorage.setItem('recommendation-popup-'+snippetId+'-dismissed-until', dismissedUntil);
        //hide popup
        container.attr('hidden', 'hidden');
    });
}

function checkPopupDismissed(container, snippetId, currentDate) {
    if(localStorage.getItem('recommendation-popup-'+snippetId+'-dismissed-until')) {
        let dismissedUntil = localStorage.getItem('recommendation-popup-'+snippetId+'-dismissed-until');
        return (currentDate < dismissedUntil);
    }
    else return false;
}