"use strict";
import initModulesInScope from "@elements/init-modules-in-scope";
import {initInScope as reloadInit} from "@elements/reload-form";

export function initInScope ($scope) {
    $scope.find('.js-rating-modal__toggle').on('click', function () {
        let $toggle = $(this);
        $toggle.attr("disabled", "disabled");

        let modalId = $(this).attr('data-target');
        let ratingId = $(this).attr('data-rating-id');
        let hasReloadForm = $(this).attr('data-reload-form');
        let url = $(this).attr('data-rating-url');
        let modal = document.getElementById(modalId.substr(1, modalId.length));
        let input = $(modal).find('.js-ratings__id-input');
        input.value = ratingId;

        let data = {
            "id": ratingId
        };

        let pendingRequest = $.ajax(url, {
            method: "POST",
            dataType : 'json',
            data: data
        }).done(function(response) {
            const modal = $(response.html);
            $('body').append(modal);
            modal.modal('show');
            setTimeout(function(){
                initModulesInScope(modal);
                reloadInit(modal);
            }, 500);
            modal.on({
                'hide.bs.modal':function(){
                    modal.remove();
                    $toggle.attr("disabled", false);
                }
            });
        }).always(function() {
            pendingRequest = null
        });
    });
}