"use strict";

import 'slick-carousel';
import {loadImg} from "@elements/lazy-img";
import {onEnterViewPort} from "@elements/viewport-utils";

export function initInScope($scope) {
    $scope.find('.js-hero-slider').each(function () {
        let $slider = $(this);

        let $settings = {
            infinite: true,
            dots: true,
            prevArrow:"<button type='button' class='slick-prev slick-arrow--small' aria-label='prev'></button>",
            nextArrow:"<button type='button' class='slick-next slick-arrow--small' aria-label='next'></button>",
            appendArrows: $('.js-hero-slider__arrow-holder')
        };

        $slider.slick($settings).on('afterChange', function (event, slick, currentSlide) {
            preloadSlide(slick, currentSlide + 1);

            /* set active Nr */
            let currentSlideInt = Math.min(parseInt(currentSlide + 1));
            $slider.closest('.js-hero-slider-wrapper').find('.js-hero-slider__current').html(currentSlideInt);
        });

        onEnterViewPort($slider, function ($slider) {
            preloadSlide($slider.slick('getSlick'), 1);
        });

    });
}


export function preloadSlide(slick, slideIndex) {
    let slideToPreload = slick.$slides[slideIndex];

    if (slideToPreload) {
        let $imgToPreload = $(slideToPreload).find('.js-lazy-img');
        if ($imgToPreload && $imgToPreload.length) {
            loadImg($imgToPreload);
        }
    }
}