export function init() {
    let $mute = $('.js-video-mute__btn-mute');
    let $unmute = $('.js-video-mute__btn-unmute');
    let video = $('.js-video-mute__video')[0];

    $('.js-video-mute__btn').on('click', function () {
        if ($mute.attr('hidden') === 'hidden') {
            $mute.attr('hidden', null);
            $unmute.attr('hidden', 'hidden');
            video.muted = false;
        } else {
            $mute.attr('hidden', 'hidden');
            $unmute.attr('hidden', null);
            video.muted = true;
        }
    });
}
