"use script";

import {isUrl, requireConfigs, getConfig} from "./utils";
import * as Chartist from "chartist";
import ChartistAxisTitlePlugin from "chartist-plugin-axistitle";
//import {tourMapRemoveCurrentPosition , tourMapSetCurrentPosition } from "./tourMap";


let polylines = [];

export function init() {

    requireConfigs({
        name: 'tourHeightChartDataUrl',
        validator: isUrl
    }, 'lang');

    const $element = $('.js-tour-height-chart');
    let xAxisLabel = $('.js-tour-height-chart__canvas').attr('data-label-xAxis') || null,
        yAxisLabel = $('.js-tour-height-chart__canvas').attr('data-label-yAxis') || null,
        graphData;

    fetch(getConfig('tourHeightChartDataUrl'))
        .then(response => response.json())
        .then((data) => {
            graphData = data;
            drawGraph(graphData, xAxisLabel, yAxisLabel);
            return graphData;
        }).then((data) => {
        fetch(getConfig('tourHeightChartPolyDataUrl'))
            .then(response => response.json())
            .then((data) => {
                if (matchMedia('(min-width: 768px)')) {
                    polylines = addPolylines(data);
                    snapToPoints($element, polylines, graphData);
                }
            });
    });
}

export function addPolylines(poly) {
    let polyline = [];

    poly.polylines.forEach(function (elem, index) {
        polyline[index] = [elem.lat, elem.lon];
    });
 
    return polyline;
}

export function drawGraph(data, xAxisLabel, yAxisLabel) {
    let options = {
        fullWidth: true,
        showArea: false,
        snapToGrid: true,
        height: 350,
        axisY: {
            onlyInteger: true,
        },
        axisX: {
            labelInterpolationFnc: function skipLabels(value, index) {
                return index % Math.round(data.series[0].length/10) === 0 ? value : null;
            }
        },
        chartPadding: {
            top: 0,
            right: 0,
            bottom: 25,
            left: 25
        },
        plugins: [
            ChartistAxisTitlePlugin({
                axisX: {
                    axisTitle: xAxisLabel,
                    axisClass: 'ct-axis-title',
                    offset: {
                        x: 0,
                        y: 40
                    },
                    textAnchor: 'middle'
                },
                axisY: {
                    axisTitle: yAxisLabel,
                    axisClass: 'ct-axis-title',
                    offset: {
                        x: 0,
                        y: 0
                    },
                    textAnchor: 'middle',
                    flipTitle: false
                }
            })
        ]
    };

    let chartHeight = new Chartist.Line('.js-tour-height-chart__canvas', data, options);

    chartHeight.on('draw', function (data) {
        if (data.type === 'line' || data.type === 'area') {
            data.element.animate({
                d: {
                    begin: 50 * data.index,
                    dur: 300 * Math.pow(.9, data.index),
                    from: data.path.clone().scale(1, 0).translate(0, data.chartRect.height()).stringify(),
                    to: data.path.clone().stringify(),
                    easing: Chartist.Svg.Easing.easeOutQuint
                }
            });
        }
    });
    return data;
}

export function snapToPoints($container, polylines, data) {
    let elements = Array.from(document.querySelectorAll('.ct-point')),
        $chart = $container.find('.js-tour-height-chart__canvas'),
        $toolTip = generateTooltip($chart),
        closestElement,
        isCombinedWithTourMap = $chart.attr('data-combined-with-tour-map') || false;

    let pointCoords = elements.map(point => {
        let rect = point.getBoundingClientRect();
        return [rect.x, rect.y];
    });

    $chart.on("mousemove", function (ev) {

        //get closest element
        let distances = [];
        pointCoords.forEach(pointCoord => {
            let distance = Math.hypot(pointCoord[0] - parseInt(ev.clientX));
            distances.push(parseInt(distance));
        });

        let closestElementIndex = distances.indexOf(Math.min(...distances));
        closestElement = elements[closestElementIndex];

        $container.find('.ct-point').removeClass('ct-point-hover');
        $(closestElement).addClass('ct-point-hover');

        placeTooltip($toolTip, closestElement, $chart, data, closestElementIndex);

        if(isCombinedWithTourMap) {
            tourMapRemoveCurrentPosition();
            tourMapSetCurrentPosition(polylines[closestElementIndex][0], polylines[closestElementIndex][1]);
        }
    });

    $chart.on("mouseleave", function () {
        $toolTip.hide();
    });
}

export function generateTooltip($chart) {
    let $toolTip = $chart
        .append('<div class="ct-tooltip"></div>')
        .find('.ct-tooltip')
        .hide();

    return $toolTip;
}

export function placeTooltip($toolTip, point, $chart, data, index) {
    let xAxisLabel = $chart.attr('data-label-xAxis'),
        yAxisLabel = $chart.attr('data-label-yAxis');

    let heightValue = $(point).attr('ct:value') || '0',
        lengthValue = data.labels[index] || '0',
        tooltipText = yAxisLabel + ": " + heightValue + "m<br/>" + xAxisLabel + ": " + lengthValue + "km";

    $toolTip.html(tooltipText).show();

    $toolTip.css({
        left: (point.getBoundingClientRect().x) - ($toolTip.width() / 2 + $chart.offset().left + 3),
        top: ($(point).attr('y1')) - $toolTip.height() - 20
    });
}