"use strict";

import 'slick-carousel';

export function initInScope($scope) {
    $scope.find('.js-ratings-slider').each(function () {
        let $slider = $(this);
        let $sliderWrapper = $slider.closest('.js-ratings-teaser__slider-wrapper');
        let $sliderCurrent = $sliderWrapper.find('.js-ratings-slider__current');

        let $settings = {
            prevArrow: '<button type="button" class="slick-prev ratings-slider__arrow" aria-label="prev"></button>',
            nextArrow: '<button type="button" class="slick-next ratings-slider__arrow" aria-label="next"></button>',
            appendArrows: $(this).closest($sliderWrapper).find('.js-ratings-slider__arrows'),
            dots: true,
            infinite: false,
            touchMove: false,
            draggable: false,
            fade:true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 767,
                    settings: {
                        draggable: true,
                        speed: 750,
                        //touchThreshold:3
                    }
                },
            ]
        };


        $slider.on('afterChange', function (event, slick, currentSlide) {
            /* set active Nr */
            let currentSlideInt = Math.min(parseInt(currentSlide + 1));
            $sliderCurrent.html(currentSlideInt);

        }).slick($settings);

    });
}
