"use strict";

import {preloadSlide} from "@elements/slider";
import {onEnterViewPort} from "@elements/viewport-utils";

let sliderId = 0;

export function initInScope($scope) {

    let $thumbSlider = $scope.find('.js-gallery-slider');

    import('slick-carousel').then(function () {
        $thumbSlider.each(function () {
            let $container = $(this);
            let $mainSlider = $container.find('.js-gallery-slider__main');
            let $thumbSlider = $container.find('.js-gallery-slider__thumbs');
            let id = sliderId++;

            $mainSlider.attr('id', 'main-slider__main-' + id);
            $thumbSlider.attr('id', 'thumb-slider__thumb-' + id);

            $mainSlider.on('afterChange', function (event, slick, currentSlide) {
                preloadSlide(slick, currentSlide + (matchMedia('(max-width: 767px)').matches ? 2: 1));

                /* set active Nr */
                let currentSlideInt = Math.min(parseInt(currentSlide + 1));
                $mainSlider.closest('.js-gallery-slider').find('.js-gallery-slider__current').html(currentSlideInt);

            }).slick({
                asNavFor: '#thumb-slider__thumb-' + id,
                dots: true,
                prevArrow:"<button type='button' class='slick-prev slick-arrow--small' aria-label='prev'></button>",
                nextArrow:"<button type='button' class='slick-next slick-arrow--small' aria-label='next'></button>",
                appendArrows: $(this).closest($container).find('.js-gallery-slider__arrows'),
                infinite: false,
                responsive: [{
                    breakpoint: 767,
                    settings: {
                        asNavFor: null,
                        slidesToShow: 1,
                    }
                }]
            }).each(function () {
                let $slider = $(this);
                onEnterViewPort($slider, function () {
                    preloadSlide(
                        $slider.slick('getSlick'),
                        (matchMedia('(max-width: 767px)').matches ? 2: 1)
                    );
                });
            });

            if ($thumbSlider && $thumbSlider.length) {
                $thumbSlider.slick({
                    dots: true,
                    arrows:false,
                    infinite: false,
                    asNavFor: '#main-slider__main-' + id,
                    focusOnSelect: true,
                    slidesToShow: 8
                });
            }
        });
    });
}