"use strict";

export function initInScope ($scope) {
    $.getScript("/static/js/libs/imagesloaded.min.js").done(function() {
        $.getScript("/static/js/libs/masonry.min.js").done(function() {
            let $grid = $('.js-masonry-gallery').imagesLoaded(function () {
                $grid.masonry({
                    // options
                    itemSelector: '.js-masonry-gallery__item',
                    //columnWidth: 200,
                    //columnWidth: '.js-masonry-gallery__item',
                    //percentPosition: true,
                    gutter: 0
                });
            });
        });
    });
}