"use strict";

import loadScript from '@elements/load-script';
export function init() {
    $('head').append('<link rel="stylesheet" type="text/css" href="https://static.maptoolkit.net/api/v9.7.9/mtk.css">');

    loadScript("https://static.maptoolkit.net/api/v9.7.9/mtk.js").done(function () {

        let lat = $('.js-toursprung-map--single-poi').attr('data-lat');
        let lng = $('.js-toursprung-map--single-poi').attr('data-lng');

        MTK.init({
            apiKey: _config.toursprungKey, language: _config.lang
        }).createMap(
            "map",
            {
                map: {
                    location: { center: { lng: lng, lat: lat }, zoom: _config.toursprungZoom || 12 },
                    mapType: "schladming_dachstein-terrain",
                    controls: [
                        [new mapboxgl.NavigationControl(), "bottom-left"],
                        /*[new mapboxgl.FullscreenControl(), "bottom-left"],*/
                    ]
                }
            },
            map => {
                new MTK.Marker().setImage({ id: "poi-schladming", anchor: "bottom"}).setLngLat([lng, lat]).addTo(map);
            }
        );

    });
}