"use strict";

export function initInScope ($scope) {
    $('.js-watchlist-add-toggle').on('click', function (evt) {
        console.warn("popover test2----------------------------");
        evt.preventDefault();
        //$('input[name="createNewWatchlist"]').val('1');

        $('.js-watchlist-add-toggle').addClass('d-none');
        $('.js-watchlist-add-toggle__input').removeClass('d-none');


    })
}