"use strict";

export function initInScope ($scope) {
    $scope.find('.js-checkbox-teaser').on('click',function (e) {
        if($(this).hasClass('checkbox-teaser--checked')) {
            $(this).removeClass('checkbox-teaser--checked');
            $(this).find('.js-checkbox-teaser__checkbox').prop('checked',false);
        } else {
            $(this).addClass('checkbox-teaser--checked');
            $(this).find('.js-checkbox-teaser__checkbox').prop('checked','checked');
        }
    });
}