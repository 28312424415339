"use strict";

export function initInScope ($scope) {
    $scope.find('.js-img-upload').each(function () {

        let $imgUpload = $(this);

        if($(this).hasClass("js-img-upload-eem")) {
            let $eemBox = $(this).closest(".eem-box");
            let $imgCopyright = $eemBox.find(".js-eem-image-copyright");
            let $imgTitle = $eemBox.find(".js-eem-image-title");
            let $imgDsgvoCheckbox = $eemBox.find(".js-eem-image-dsgvo");
            let $imgUploadPreview = $eemBox.find(".js-img-preview");

            if($(this)[0].files.length === 0 && $imgUploadPreview.css("background-image") === "none") {
                $imgCopyright.removeAttr("required");
                $imgTitle.removeAttr("required");
                $imgDsgvoCheckbox.removeAttr("required");
            }

            $(this).on("change", function () {
                if($(this)[0].files.length !== 0) {
                    $imgCopyright.attr("required", "required");
                    $imgTitle.attr("required", "required");
                    $imgDsgvoCheckbox.attr("required", "required");
                }
            });
        }

        function readURL(input) {
            if (input.files && input.files[0]) {
                let reader = new FileReader();

                reader.onload = function(e) {
                    $imgUpload.closest('.js-img-preview').css('background-image', "url('"+ e.target.result +"')");
                }

                reader.readAsDataURL(input.files[0]);
            }
        }

        $imgUpload.change(function() {
            readURL(this);
        });

    });


}
