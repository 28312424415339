"use strict";

export function initInScope ($scope) {

    if(_config.ajaxOnLoadUrl){

        var url = _config.ajaxOnLoadUrl;
        $.ajax({
            type:'POST',
            url: url,
            success:function(data){
                console.log(`ajaxRequest to ${url} successful`)
            }
        });

    }

}



