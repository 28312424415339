"use strict";

export function initInScope ($scope) {
    $scope.find('.js-collapse-list-poi__list').on('click',function (e) {
        e.preventDefault();

        $('.js-collapse-list-poi').removeClass('active');
        $('.js-hide-if-poi').removeAttr('hidden');

        $(this).addClass('active');
        $('#collapse-list').collapse('show');
        $('#collapse-poi').collapse('hide');
    });


    $scope.find('.js-collapse-list-poi__poi').on('click',function (e) {
        e.preventDefault();

        $('.js-collapse-list-poi').removeClass('active');
        $('.js-hide-if-poi').attr('hidden','hidden');

        $(this).addClass('active');
        $('#collapse-list').collapse('hide');
        $('#collapse-poi').collapse('show');
    });
}