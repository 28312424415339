"use strict";
import {isValid} from "@elements/parsley-bootstrap-validation";

export function initInScope ($scope) {
    $scope.find('.js-tracking-customer-login').on('click',function (e) {
        var $this = $(this);
        var $form = $this.closest('form');
        var trackingEvent = $this.data('trackingEvent');
        var trackingType = $this.data('trackingType');

        if($form.length !== 0) {
            if (isValid($form)) {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    'event': trackingEvent,
                    'type': trackingType
                });
            }
        } else {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                'event': trackingEvent,
                'type': trackingType
            });
        }
    });
}