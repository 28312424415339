"use strict";

export function initInScope ($scope) {
    $scope.find('.js-img-teaser').each(function () {
        let $teaser = $(this);
        let $playButton = $teaser.find('.js-img-teaser__playbutton');
        let $pauseButton = $teaser.find('.js-img-teaser__pausebutton');
        let $videoWrapper = $teaser.find('.js-img-teaser__video-wrapper');
        let $video = $teaser.find('.js-img-teaser__video');
        $playButton.on('click', function (evt) {
            evt.stopPropagation();
            $videoWrapper.removeClass('d-none').addClass('is-playing');
            $video.get(0).play();
            $playButton.addClass('d-none');
            $pauseButton.removeClass('d-none');
        });
        $pauseButton.on('click', function (evt) {
            evt.stopPropagation();
            $videoWrapper.addClass('d-none').removeClass('is-playing');
            $video.get(0).pause();
            $playButton.removeClass('d-none');
            $pauseButton.addClass('d-none');
        })
    });
}