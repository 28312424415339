"use strict";

import 'slick-carousel';

export function initInScope($scope) {
    $scope.find('.js-gamification-slider').each(function () {
        let $slider = $(this);
        let $sliderWrapper = $slider.closest('.js-gamification-slider-wrapper');
        let url = $(this).attr('data-url');

        let $settings = {
            prevArrow: '<button type="button" class="slick-prev gamification-slider__arrow" aria-label="prev"></button>',
            nextArrow: '<button type="button" class="slick-next gamification-slider__arrow" aria-label="next"></button>',
            appendArrows: $(this).closest($sliderWrapper).find('.js-gamification-slider__arrows'),
            dots: true,
            infinite: false,
            touchMove: false,
            draggable: false,
            fade:true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 767,
                    settings: {
                        draggable: true,
                        speed: 750,
                        //touchThreshold:3
                    }
                },
            ]
        };

        $slider.slick($settings);

        let $gamificationButton = $sliderWrapper.find('.js-gamification-button');
        let $dislikeButton = $sliderWrapper.find('.js-gamification-button--dislike');
        let $likeButton = $sliderWrapper.find('.js-gamification-button--like');
        let answer = true;


        $gamificationButton.on('click', function (evt) {
            answer = $(this).attr('data-answer');
            questionAnswer(answer);
        });


        $slider.on('afterChange', function(event, slick, currentSlide) {
            console.log(slick, currentSlide);
            if (slick.$slides.length-1 == currentSlide) {
                $sliderWrapper.addClass('is-finished');
            } else {
                $sliderWrapper.removeClass('is-finished');
            }

            let questionActiveSlide = $sliderWrapper.find('.slick-active .js-gamification-slider__item').attr('data-question');
            $slider.attr('data-current-question',questionActiveSlide);

            let currentSlideId = $sliderWrapper.find('.slick-active .js-gamification-slider__item').attr('data-slide-id');
            $slider.attr('data-current-slide',currentSlideId);
        });

        $slider.on('swipe', function(event, slick, direction){
            if(direction === "right"){

                let slickIndex = parseInt($sliderWrapper.find('.slick-active').attr('data-slick-index'));

                $slider.slick('unslick');
                $slider.slick($settings);
                let gotoSlideId = $slider.attr('data-current-slide');
                $slider.slick('slickGoTo',gotoSlideId);

                if (matchMedia('(max-width: 767px)').matches) {
                    $likeButton.addClass('active');
                    answer = "like";
                    questionAnswer(answer);
                    setTimeout(function() {
                        $likeButton.removeClass("active");
                    }, 500);
                }
            } else {

                if (matchMedia('(max-width: 767px)').matches) {
                    $dislikeButton.addClass('active');
                    answer = "dislike";
                    questionAnswer(answer);
                    setTimeout(function() {
                        $dislikeButton.removeClass("active");
                    }, 500);
                }
            }
        });


        function questionAnswer(answer) {
            let question = $slider.attr('data-current-question');
            console.log(question);
            console.log(answer);

            $.ajax(url, {
                method: 'POST',
                data: 'question=' + question + '&answer=' + answer,
                //dataType: 'json',
                //headers: {ajax: true}
            }).done(function (data) {
                if(data.success === true) {
                    $slider.slick('slickNext');
                }
            }).fail(function(a){
                console.warn(a);
            });
        }


    });
}
