"use strict";

export function initInScope($scope) {
    let $slider = $scope.find('.js-loyalty-teaser-slider');

    import('slick-carousel').then(function () {
        $slider.each(function () {
            let $this = $(this);

            let slidesToShow = $this.data('slider-slides-to-show') || 4,
                dots = $this.data('slider-dots') || false,
                arrows = true;

            if (matchMedia('(max-width: 767px)').matches) {
                slidesToShow = 1;
                dots = true;
                arrows = false;
            }
            $this.slick({
                slidesToShow: slidesToShow,
                arrows: arrows,
                dots: dots,
                infinite: false
            });
        });
    });
}