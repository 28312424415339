"use strict";

import {setCheckboxImage, setCheckboxState} from "./boxCheckbox";

export function initInScope ($scope) {
    $scope.find('.js-reset-profile').on('click', function () {
        //$(this).closest('form')[0].reset();
        $(this).closest('form').find('.js-datepicker__input').removeClass('has-value');
        $(this).closest('form').find('.js-box-checkbox').removeClass('is-checked');
        $(this).closest('form').find('.js-box-checkbox__input').prop('checked',null);

        $(this).closest('form').submit();

    });
}