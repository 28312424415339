"use strict";

import loadScript from '@elements/load-script';
export function initInScope($scope) {

    loadScript("/static/js/libs/nouislider.min.js").done(function () {

        $scope.find('.js-range-slider').each(function () {
            var $this = $(this);
            var sliderElement = $this.find('.js-range-slider__slider')[0];
            var min = $this.data('min-value') || 0;
            var max = $this.data('max-value') || 100;
            var minInput = $this.find('.js-range-slider__min');
            var maxInput = $this.find('.js-range-slider__max');
            var decimalPlaces = 0;
            var steps = $this.data('steps') || 1;
            var toolTipLabels = $this.data('tooltip-labels');

            if (typeof toolTipLabels == 'string') {
                toolTipLabels = JSON.parse(toolTipLabels);
            }

            var startMin = min;
            var startMax = max;

            if (minInput.val()) {
                if (toolTipLabels) {
                    startMin = toolTipLabels.indexOf(minInput.val());
                } else {
                    startMin = minInput.val();
                }
            } else {
                startMin = min;
            }
            if (maxInput.val()) {
                if (toolTipLabels) {
                    startMax = toolTipLabels.indexOf(maxInput.val());
                } else {
                    startMax = maxInput.val();
                }
            } else {
                startMax = max;
            }

            if ($this.data('steps') < 1) {
                decimalPlaces = $this.data('steps').toString().length - 2;
            }

            noUiSlider.create(sliderElement, {
                range: {
                    'min': [min],
                    'max': [max]
                }
                , start: [startMin, startMax]
                , handles: 3
                , connect: true
                , step: steps
                , tooltips: [
                    {
                        to: function (value) {
                            value = value || 0;

                            if (toolTipLabels && toolTipLabels.length && toolTipLabels[value / steps]) {
                                return toolTipLabels[value / steps];
                            } else {
                                return value.toFixed(decimalPlaces);
                            }

                        },
                        from: function (value) {
                            return value;
                        }
                    }, {
                        to: function (value) {
                            value = value || 0;

                            if (toolTipLabels && toolTipLabels.length && toolTipLabels[value / steps]) {
                                return toolTipLabels[value / steps];
                            } else {
                                return value.toFixed(decimalPlaces);
                            }
                        },
                        from: function (value) {
                            return value;
                        }
                    }
                ]
            });

            sliderElement.noUiSlider.on('change', function (values, handle) {
                var value = +values[handle];

                if (handle === 0) {
                    if (toolTipLabels) {
                        minInput.val(toolTipLabels[value.toFixed(decimalPlaces)]);
                    } else {
                        minInput.val(value.toFixed(decimalPlaces));
                    }

                    minInput.trigger('change');
                } else {
                    if (toolTipLabels) {
                        maxInput.val(toolTipLabels[value.toFixed(decimalPlaces)]);
                    } else {
                        maxInput.val(value.toFixed(decimalPlaces));
                    }

                    maxInput.trigger('change');
                }
            });

            minInput.on('programmaticChange', function () {
                var value = minInput.val() || min;

                if (toolTipLabels) {
                    value = toolTipLabels[value];
                }

                sliderElement.noUiSlider.set([value, null]);
            });

            maxInput.on('programmaticChange', function () {
                var value = maxInput.val() || max;

                if (toolTipLabels) {
                    value = toolTipLabels.indexOf()[value];
                }

                sliderElement.noUiSlider.set([null, value]);
            });
        });
    });
}
