"use strict";

export function initInScope ($scope) {
    $scope.find('.js-datepicker__clear').on('click',function (e) {
        e.preventDefault();
        $(this).closest('.js-datepicker').find('input').val('');
        $(this).closest('.js-datepicker').removeClass('has-value');
        $(this).closest('.js-datepicker').find('label').removeClass('has-value');

        $(this).closest('.js-datepicker').find('.js-datepicker__overrule').val('1');
        $(this).closest('form').submit();
    });
}
