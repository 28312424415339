"use strict";

export function initInScope ($scope) {
    if (matchMedia('(max-width: 767px)').matches) {
        $scope.find('.js-dd-uz').each(function(){
            const uZWrapper = $(this);
            let $uzTxt = uZWrapper.find('.js-dd-uz__item.active');
            $uzTxt.closest('.js-dd-uz').find('.js-dd-uz__auswahl').text($uzTxt.text());
            uZWrapper.find('.js-dd-uz__item').on('click',function () {
                $(this).closest('.pimcore_area_content').find('.js-dd-uz__auswahl').text($(this).text());
            });
        });
    }
}