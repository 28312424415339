"use strict";

export function initInScope ($scope) {
    let $container = $scope.find('.js-additional-form');
    let $checkbox = $('.js-additional-form__checkbox');
    let $additionalForm = $('.js-additional-form__form');

    $container.on('click', function (evt) {
        if ($checkbox.is(':checked')){
            $additionalForm.removeAttr('hidden')
        }else{
            $additionalForm.attr('hidden', true)
        }
    });
}