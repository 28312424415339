"use strict";

export function initInScope($scope) {
    let $slider = $scope.find('.js-benefit-slider');

    import('slick-carousel').then(function () {
        $slider.each(function () {
            let $this = $(this);

            let slidesToShow = 4,
                dots = false,
                arrows = true;

            if (matchMedia('(max-width: 767px)').matches) {
                slidesToShow = 1;
                dots = true;
                arrows = false;
            }

            $this.slick({
                slidesToShow: slidesToShow,
                dots: dots,
                arrows: arrows,
                infinite: false
            });
        });
    });
}