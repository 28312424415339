import {registerPermissionGrantedCallback} from "@elements/cookie-permissions";

"use strict";

export function initInScope ($scope) {

    let category = 'A-Z Liste';
    let action = 'Filter';
    let label;

    $scope.find('.js-filter-form-tracking__search').on('change',function (e) {

        label = $(this).val();
        pushEvent(category, 'Eingabe Suche', label);

    });

    $scope.find('.js-filter-form-tracking select').on('change',function (e) {
        label = $('option:selected').text();
        pushEvent(category, action, label);
    });

    $scope.find('.js-filter-form-tracking input:checkbox').on('change',function (e) {

        label = $(this).siblings('.custom-checkbox__text').text();
        pushEvent(category, action, label);

    });

}

function pushEvent(category, action, label){

    registerPermissionGrantedCallback('google', function () {
        if(window.dataLayer){
            dataLayer.push({
                'event': 'genericGtmEvent',
                'genericEventCategory': category,
                'genericEventAction': action,
                'genericEventLabel': label
            });
        }
    });
}