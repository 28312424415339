"use strict";

export function initInScope ($scope) {
    $scope.find('.js-toggle-subcategory').on('change',function (e) {
        let subCategory = $(this).find(':selected').attr('data-subcateogry');
        let clearAll = $(this).find(':selected').attr('data-clear-all');
        let mainCatAutoSelect = $(this).find(':selected').attr('data-maincat-autoselect');

        if(clearAll) {
            $scope.find('.js-subcategory input').prop('checked',false);
            $scope.find('.js-main-category input').prop('checked',false);
        }

        if(mainCatAutoSelect) {
            $scope.find('.js-main-cat-autoselect__value').val(mainCatAutoSelect);
        } else {
            $scope.find('.js-main-cat-autoselect__value').val('');
        }

        $scope.find('.js-subcategory-placeholder').remove();

        $scope.find('.js-subcategory').attr('hidden','hidden');
        $scope.find('.js-subcategory[data-active-subcategory="'+subCategory+'"]').removeAttr('hidden');

        //$scope.find('.js-subcategory input').val('').removeClass('has-value');
        //$scope.find('.js-subcategory input').prop('checked',false);
        //$scope.find('.js-subcategory select option').prop('selected',false);
    });



    /* open on load if checked */
    $scope.find('.js-subcategory-show input').each(function(){
        let $this = $(this);
        if($this.prop('checked')) {
            $this.closest('.js-subcategory-show').addClass('show');
            $this.closest('.js-main-category').find('.js-collapse-state').removeClass('collapsed');

        }
    });
}
