"use strict";

import loadScript from '@elements/load-script';
export function init() {

    let season = _config.season;

    $('head').append('<link rel="stylesheet" type="text/css" href="https://static.maptoolkit.net/api/v9.7.9/mtk.css">');

    loadScript("https://static.maptoolkit.net/api/v9.7.9/mtk.js").done(function () {

        // helper function to get the correct internationalized text for any object and the current language
        let i18n = (obj, fallback="", prefix="", suffix="") => {
            let str = "";
            try { str = obj[MTK.language][0] || fallback }
            catch { str = typeof(obj) == "string" ? obj : fallback }
            return str ? prefix + str + suffix : str;
        };
        let i18nTwo = (obj, fallback="", prefix="", suffix="") => {
            let str = "";
            try { str = obj[MTK.language][1] || fallback }
            catch { str = typeof(obj) == "string" ? obj : fallback }
            return str ? prefix + str + suffix : str;
        };

        // create a HTML snippet for GeoJSON feature
        let renderFeature = (feature, classList="") => `
            <div class="map-teaser ${classList}">
                <div class="row no-gutters">
                    <div class="col-md-4">
                        <div class="image embed-responsive embed-responsive-3by2 embed-responsive-cover">
                            <img src="
                                 ${ season == "winter" ? feature.properties.thumbnailWinter ? feature.properties.thumbnailWinter : feature.properties.thumbnail : ""}
                                 ${ season == "summer" ? feature.properties.thumbnail ? feature.properties.thumbnail : "" : ""}
                                 " class="embed-responsive-item" />
                                 <div class="teaser__badge teaser__badge--small teaser__badge--left teaser__badge--secondary" ${ feature.properties.serviceName ? "" : "hidden"}>
                                    <div class="teaser__badge-inner">
                                        <img class="teaser__badge-sommercard" src="/static/build/img/sommercard.png" alt="">
                                        ${i18n(feature.properties.serviceBadgeText)}
                                    </div>
                                </div>
                        </div>
                    </div>
                    <div class="col-md-8">
                        <div class="map-teaser__body text-secondary">
                            <div class="map-teaser__body-top">
                                <span class="teaser__label">${i18n(feature.properties.openings)}</span>
                                <span class="teaser__label teaser__label--secondary">${i18n(feature.properties.customTeaserLabel)}</span>
                                <span class="teaser__label teaser__label--secondary">${i18n(feature.properties.relatedTo)}</span>
                                <span class="teaser__label teaser__label--secondary">${i18nTwo(feature.properties.relatedTo)}</span>
                                <span class="teaser__label teaser__label--secondary">${i18n(feature.properties.relationTo)}</span>
                                
                                <span class="teaser__label teaser__label--tour-${ feature.properties.difficulty }" ${ feature.properties.difficulty ? "" : "hidden"}>
                                    ${ feature.properties.difficulty === 1 ? translate('tour.difficulty.leicht') : "" }
                                    ${ feature.properties.difficulty === 2 ? translate('tour.difficulty.mittel') : "" }
                                    ${ feature.properties.difficulty === 3 ? translate('tour.difficulty.schwer') : "" }
                                </span>
                                
                                <div>
                                    <div class="teaser-tour__atrribute-item mr-4" ${ feature.properties.length ? "" : "hidden"}>
                                        <i class="icon icon-strecke teaser-tour__atrribute-icon"></i>
                                        <span class="teaser-tour__atrribute-text"> ${ feature.properties.length } km</span>
                                    </div>
                                    <div class="teaser-tour__atrribute-item" ${ feature.properties.duration ? "" : "hidden"}>
                                        <i class="icon icon-uhr teaser-tour__atrribute-icon"></i>
                                        <span class="teaser-tour__atrribute-text"> ${ feature.properties.duration } h</span>
                                    </div>
                                </div>
                                
                                <h3 class="h4 map-teaser__hl mb-2 title"><a target="_blank" href="${feature.properties.detaillink[MTK.language]}">${i18n(feature.properties.title, feature.properties.description)}</a></h3>
                                <a target="_blank" href="${feature.properties.detaillink[MTK.language]}" class="map-teaser__detaillink"><i class="icon icon-arrow-right"></i></a>
                                <div class="">
                                    <span>${i18n(feature.properties.categories, feature.properties.categories.de)}</span>
                                </div>
                                <div class="teaser__decorator my-3" hidden></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>`;

        // create MTK Map
        let createMap = coords => {

            MTK.init({ apiKey: _config.toursprungKey, language: _config.lang }).createMap("map", {
                map: {
                    locationHash: false,
                    location: { center: [coords.longitude, coords.latitude], zoom: _config.toursprungZoom || coords.zoom },
                    mapType: "schladming_dachstein-terrain",
                    controls: [
                        [new mapboxgl.NavigationControl(), "bottom-left"],
                        /*[new mapboxgl.FullscreenControl(), "bottom-left"],*/
                    ]
                }
            }, map => {
                window.map = map;

                // load sd_pois Features into map
                let resource = new MTK.Resource({
                    name: "sd_pois",
                    layer: {
                        type: "symbol",
                        layout: {
                            "icon-image": "poi-schladming",
                            "icon-size": [ "interpolate", ["linear"], ["zoom"], 1, 0.3, 13, 0.8, 18, 1 ],
                            "icon-ignore-placement": true,
                            "icon-allow-overlap": true,
                            "icon-anchor": "bottom",
                        }
                    }
                }).on("mouseover", e => {
                    highlight(e.feature)
                    // called after the map is dragged and the map-features are loaded for the new boundingbox
                }).on("idle", () => {
                    resource.getFeatures((err, features) => {
                        $("#itemlist").empty();
                        features.forEach(feature => $("#itemlist").append($(renderFeature(feature, "listitem"))
                            .on("click", e => highlightMarker && highlightMarker.fire("click"))
                            .on("mouseenter", e => highlight(feature))
                            .on("mouseleave", e => highlight())
                        ));
                    }, map.gl.getBounds());
                }).addTo(map);

                // update resource from the filters above the map
                let updateFilter = () => resource.setFilter({
                    query: $('.js-toursprung-search input').val(),
                    categorie_ids: $(".js-toursprung-reset").length && $(".js-toursprung-reset").get().map(el => el.dataset.filterValue)
                });

                // prevent a form submit when changing filters
                $('#filters').on('submit', e => e.preventDefault());
                // set additionally fulltext-search changes
                $('.js-toursprung-search input').on('input', () => updateFilter());
                // toggle category ids
                $('.js-toursprung-checkbox input').on('change', function (e) {
                    let filterText = $(this).closest('.js-toursprung-checkbox').find('.custom-checkbox__text').text();
                    let filterValue = $(this).closest('.js-toursprung-checkbox').attr('data-filter-value');
                    if (this.checked) $('.js-toursprung-quickfilter').append(`
                        <button data-filter-value="${filterValue}" class="btn mr-1 btn-secondary btn-sm js-toursprung-reset">
                            ${filterText}<span class="icon icon-close ml-2 fs0-75 pointer-events-none" aria-label="Reset" title="Reset"></span>
                        </button>`
                    );
                    else $(`.js-toursprung-reset[data-filter-value="${filterValue}"]`).remove();
                    updateFilter();
                });

                // handle highlight marker
                let highlightMarker = null, clickMarker = null;
                let highlight = feature => {
                    if (highlightMarker) highlightMarker.remove();
                    if (feature) highlightMarker = new MTK.Marker()
                        .setImage({ id: "poi-schladming-dunkel", anchor: "bottom"})
                        .setLabel({ text: i18n(feature.properties.title), anchor: "left", offset: [ 1.5, -1.5 ] });
                    highlightMarker.on("mouseout", () => highlightMarker.remove());

                    function highlightedMarker() {
                        if (clickMarker) clickMarker.remove();
                        if (clickPolyline) clickPolyline.remove();
                        clickMarker = highlightMarker;
                        highlightMarker = null;
                        resources[feature.properties.resource].fetch(feature, (err, f) => {
                            if (f.geometry.type.match(/LineString/)) clickPolyline = new MTK.OutlinedPolyline({
                                color: "#b1c200",
                                width: 6,
                                opacity: 1
                            }).setLngLats(f.geometry.coordinates).addTo(map);
                            $("#itemview").show().html(renderFeature(f, "js-go-to-link isCursor"));
                            $("#close").show();
                            $("#itemlist").hide();
                        });
                    }

                    if (matchMedia('(min-width: 768px)').matches) {
                        highlightMarker.on("click", () => {
                            highlightedMarker();
                        }).setLngLat(feature.geometry.coordinates).addTo(map);
                    }else {
                        highlightedMarker();
                    }

                };

                // show the itemlist. this method is called, when a user clicks on the close-button.
                window.showItemList = () => {
                    $("#itemview").hide();
                    $("#close").hide();
                    $("#itemlist").show();
                    if (clickMarker) clickMarker.remove();
                };
            });
        };
        createMap({ latitude: 47.4095, longitude: 13.8563, zoom: 10 });
    });
}
