"use strict";

export function initInScope ($scope) {

    $scope.find('.js-star-rating').each(function () {
        let $starRating = $(this);
        let $stars = $starRating.find('.js-star');
        let $form = $starRating.closest('form');
        let $starInput = $form.find('.js-number-of-stars');

        $stars.each(function () {
            let $currentStar = $(this);
            $currentStar.on('click', function(e){
                $currentStar.prevAll('.js-star').addClass('active-star');
                $currentStar.nextAll('.js-star').removeClass('active-star');
                $currentStar.addClass('active-star');
                let currentRating = $currentStar.data('number');
                $starInput.attr('value', currentRating);
            });
        });
    });
}

