import {registerPermissionGrantedCallback} from "@elements/cookie-permissions";
import {onEnterViewPort} from "@elements/viewport-utils";

export function initInScope($scope) {
    $scope.find('.js-social-wall').each(function () {
        let $this = $(this);
        let $link = $this.find('.js-social-wall__link');

        registerPermissionGrantedCallback('snapshortboard', function () {

            let settings = {
                offset: window.innerHeight / 2
            };

            onEnterViewPort($this, function () {
                console.log("is in viewport");
                let script = document.createElement('script');
                script.setAttribute('src', $this.data('src'));
                $link.attr('href', $link.data('href'));
                $this[0].appendChild(script);
            }, settings);
        })
    });
}