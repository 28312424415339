"use strict";

import loadScript from '@elements/load-script';

export function initInScope ($scope) {
    loadScript("/static/build/js/libs/jquery.inview.js").then(() => {

        $('.js-facilities-wrapper').each(function () {

            let $this = $(this);

            $(this).on('inview', function(event, isInView) {

                if (isInView) {
                    loadScript("/static/build/js/libs/countUp.min.js").then(() => {

                        $this.find('.js-counter').each(function () {
                            let options = {
                                useEasing: true,
                                useGrouping: true,
                                separator: '.',
                                decimal: ',',
                                prefix: '',
                                suffix: ''
                            };
                            let from = 0;
                            let to = 0;
                            if ($(this).data('value') !== undefined) {
                                to = $(this).data('value');
                            };

                            let counter = new CountUp(this, from, to, 0, 0, options);
                            counter.start();
                        });

                    });

                    loadScript("/static/build/js/libs/progressbar.min.js").then(() => {

                        $this.find('.js-circle-animation').each(function () {
                            let $this = $(this),
                                valueCurrent = $this.data('value-current'),
                                valueAll = $this.data('value-all'),
                                valuePercentage = valueCurrent/valueAll;

                            let circle = new ProgressBar.Circle(this, {
                                strokeWidth: 8,
                                easing: 'easeInOut',
                                duration: 1400,
                                color: '#6a8a00',
                                trailColor: '#eeedec',
                                trailWidth: 8,
                                svgStyle: null
                            });

                            circle.animate(valuePercentage);  // Number from 0.0 to 1.0

                        });


                    });


                    $(this).off('inview');
                }
            });
        });
    }).catch(console.error);




}